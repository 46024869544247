<template>
  <div class="content">
    <component :is="layout">
      <router-view />
    </component>

    <SnackbarComponent />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, inject, onMounted } from 'vue';
import { useRoute, useRouter, RouteLocationNormalizedLoaded } from 'vue-router';
import SnackbarComponent from '@/components/shared/SnackbarComponent.vue';
import Default from '@/components/layouts/Default.vue';
import { useAuthStore } from '@/store/authStore';
import { useDataStore } from '@/store/dataStore';
import { errorsObjectType } from '@/types/common';
import { errorsConst } from '@/constants/common';
//import { getAuthObjectLS } from '@/utils/getAuth';
import { createClassUser } from '@/utils/getAuth';
import type { UserLoginResponse } from '@/types/common';

type stateType = {
  errors: errorsObjectType;
  preloader: boolean;
};

export default defineComponent({
  name: 'App',
  components: {
    Default,
    SnackbarComponent,
  },
  setup() {
    const axios: any = inject('axios');
    const route: RouteLocationNormalizedLoaded = useRoute();
    const router = useRouter();
    const layout = computed(() => route?.meta.layout || 'Default');
    const store = useAuthStore();
    const dataStore = useDataStore();

    const state: stateType = reactive({
      errors: errorsConst,
      preloader: false,
    });

    const autologin = (): void => {
      const authDocs = localStorage.getItem('authDocs');
      if (authDocs) {
        const data: UserLoginResponse = JSON.parse(authDocs);
        setAxiosToken(data.token);
        store.setUser(data, true);
        dataStore.getBaseData();
      }
    };
    autologin();

    function setAxiosToken(token: string) {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        console.warn('Токен не найден');
      }
    }

    return {
      layout,
      state,
    };
  },
});
</script>

<style lang="scss">
.content {
  overflow: hidden;
}
</style>
