<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-class"
  >
    <path
      d="M11 16H3C1.89543 16 1 15.1046 1 14V6.60198C1 6.006 1.26579 5.44108 1.72494 5.06113L6.07779 1.45915C6.43643 1.16238 6.88735 1 7.35285 1H11C12.1046 1 13 1.89543 13 3V14C13 15.1046 12.1046 16 11 16Z"
      fill="none"
      :stroke="iconColor"
    />
    <line
      x1="10.5"
      y1="12.5"
      x2="3.5"
      y2="12.5"
      :stroke="iconColor"
      stroke-linecap="round"
    />
    <line
      x1="10.5"
      y1="10.5"
      x2="3.5"
      y2="10.5"
      :stroke="iconColor"
      stroke-linecap="round"
    />
    <line
      x1="10.5"
      y1="8.5"
      x2="3.5"
      y2="8.5"
      :stroke="iconColor"
      stroke-linecap="round"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'iconPaper',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewBox: {
      type: String,
      default: '0 0 18 18',
    },
  },
};
</script>

<style scoped>
.icon-class {
  border-color: #ffffff;
  color: #ffffff;
}
/* .icon-class:hover {
  background-color: #ffffff;
  border-color: #075988;
  color: #075988;
} */
</style>
