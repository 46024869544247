<template>
  <iconsWrapper :width="width" :height="height" :viewBox="viewBox">
    <path
      d="M9.94667 8.16675L15.6 2.52675C15.8667 2.26008 16 1.91341 16 1.55341C16 1.19341 15.8533 0.833415 15.6 0.580081C15.0933 0.0734147 14.1467 0.0734147 13.64 0.580081L8 6.22008L2.36 0.566748C2.09333 0.300081 1.74667 0.166748 1.38667 0.166748C1.02667 0.166748 0.666667 0.313415 0.4 0.566748C0.146667 0.833415 0 1.18008 0 1.55341C0 1.92675 0.146667 2.27341 0.4 2.52675L6.04 8.16675L0.4 13.8067C0.146667 14.0734 0 14.4201 0 14.7934C0 15.1534 0.146667 15.5001 0.4 15.7667C0.653333 16.0334 1.01333 16.1667 1.37333 16.1667C1.73333 16.1667 2.09333 16.0201 2.34667 15.7667L7.98667 10.1267L13.6267 15.7667C14.1333 16.2734 15.08 16.2734 15.5733 15.7667C15.84 15.5001 15.9733 15.1534 15.9733 14.7934C15.9733 14.4334 15.8267 14.0734 15.5733 13.8201L9.93333 8.18008L9.94667 8.16675Z"
      :fill="iconColor"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'IconClose',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
    viewBox: {
      type: String,
      default: '0 0 18 18',
    },
  },
};
</script>
