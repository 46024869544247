<template>
  <div class="app-container home">
    <div class="home__form">
      <QUploader
        v-show="filesData.length === 0"
        ref="uploaderRef"
        v-model="filesData"
        :auto-upload="false"
        @added="onFilesAdded"
        :multiple="isMultipleUploading"
        :max-files="settings.uploadMaxOnTimeCount"
      >
        <template v-slot:header></template>
        <template v-slot:list="scope">
          <div class="home__upload">
            <div class="home__upload-content">
              <img alt="logo" src="../assets/img/upload.png" />
              <button
                class="btn btn-primary home__upload-btn"
                @click="scope.pickFiles"
              >
                <QUploaderAddTrigger />
                Выберите файл для загрузки
              </button>
              <span class="home__upload-rules" v-if="!isAuth">
                {{ uploadRulesText }}
              </span>
            </div>
          </div>
        </template>
      </QUploader>
      <div v-show="filesData.length !== 0">
        <div class="home__uploading">
          <div class="home__uploading-head">Идет анализ файлов:</div>
        </div>
        <div
          class="home__uploading-file"
          v-for="(file, index) in filesData"
          :key="index"
        >
          <div class="home__uploading-name">
            <span>{{ file.name }}</span>
            <!-- <iconClose class="link"></iconClose> -->
          </div>
        </div>
        <div class="progress-bar">
          <div
            class="progress-bar__inner"
            :style="{
              width: state.progress + '%',
            }"
          ></div>
        </div>
        <div class="progress-bar__counter">
          {{ state.finishedRequsts }}&nbsp;/&nbsp;{{ filesData.length }}
        </div>
      </div>

      <div
        class="home__upload-results upload-results"
        v-if="Object.keys(form.uploadDocsData).length"
      >
        <p class="upload-results__key-title">
          Результаты анализа загруженных документов:
        </p>
        <ul class="upload-results__search-list">
          <li v-for="document in form.uploadDocsData" :key="document.filename">
            <div class="upload-results__search-docs-list">
              <div class="upload-results__search-item">
                <div class="upload-results__search-item-content">
                  <ul class="upload-results__search-content-wtapper">
                    <li class="upload-results__search-item-doc-wrapper">
                      <QExpansionItem>
                        <template v-slot:header>
                          <div class="upload-results__search-export-wrapp">
                            <span class="upload-results__search-item-doc">
                              {{ document.filename }}
                            </span>
                          </div>
                        </template>
                        <div
                          class="upload-results__search-content"
                          v-for="page in document.pages"
                          :key="page.id"
                        >
                          <div class="upload-results__search-item-doc">
                            Страница {{ page.page_number }}:
                            <template v-if="page.document_types.length > 0">
                              <span
                                v-for="(type, index) in page.document_types"
                                :key="index"
                              >
                                {{ type }}
                              </span>
                            </template>
                            <template v-if="page.error">
                              <span>{{ page.error }}</span>
                            </template>
                          </div>

                          <div
                            class="upload-results__search-item-doc"
                            v-if="page.additional_info?.length"
                          >
                            Дополнительные параметры типа документа:
                            <span
                              v-for="(info, index) in page.additional_info"
                              :key="index"
                            >
                              {{ info }}
                            </span>
                          </div>

                          <div
                            class="upload-results__search-table"
                            v-if="page.order_numbers?.length"
                          >
                            <div class="upload-results__search-row">
                              <div class="upload-results__search-cell">
                                <!-- upload-results__search-cell--left -->
                                Ключи поиска
                              </div>
                              <!-- <div class="upload-results__search-cell">
                              Дополнительная информация
                            </div> -->
                            </div>

                            <div
                              class="upload-results__search-row home__search-cell--bottom"
                              v-for="(key, index) in page.order_numbers"
                              :key="index"
                            >
                              <div class="upload-results__search-cell">
                                <!-- upload-results__search-cell--left -->
                                {{ key }}
                              </div>
                              <!-- <div class="upload-results__search-cell">
                              <template
                                v-if="
                                  page.additional_info &&
                                  page.additional_info[key]
                                "
                              >
                                {{ page.additional_info[key] }}
                              </template>
                              <template v-else>--</template>
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </QExpansionItem>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <!-- <li v-for="document in form.uploadDocsData" :key="document.filename">
            <div class="upload-results__search-docs-list">
              <div class="upload-results__search-item">
                <div class="upload-results__search-item-content">
                  <ul class="upload-results__search-content-wtapper">
                    <li class="upload-results__search-item-doc-wrapper">
                      <QExpansionItem>
                        <template v-slot:header>
                          <div class="upload-results__search-export-wrapp">
                            <span class="upload-results__search-item-doc">
                              {{ document.filename }}
                            </span>
                            <div class="upload-results__search-export-btn">
                              <span
                                class="upload-results__search-export-name"
                                @click="downloadFile"
                                >Экспортировать в Excel</span
                              >
                              <iconExcel />
                            </div>
                          </div>
                        </template>
                        <div
                          class="upload-results__search-content"
                          v-for="page in document.pages"
                          :key="page.id"
                        >
                          <div class="upload-results__search-item-doc">
                            Страница {{ page.page_number }}:
                            <template v-if="page.document_types.length > 0">
                              <span
                                v-for="(type, index) in page.document_types"
                                :key="index"
                              >
                                {{ type }}
                              </span>
                            </template>
                            <template v-if="!page.order_numbers?.length">
                              <span>Ключи поиска не распознаны </span>
                            </template>
                            <template v-if="page.error">
                              <span
                                class="upload-results__search-export-name"
                                >{{ page.error }}</span
                              >
                            </template>
                          </div>

                          <div
                            class="upload-results__search-table"
                            v-if="page.order_numbers?.length"
                          >
                            <div class="upload-results__search-row">
                              <div
                                class="upload-results__search-cell upload-results__search-cell--left"
                              >
                                Ключи поиска
                              </div>
                              <div class="upload-results__search-cell">
                                Дополнительная информация
                              </div>
                            </div>

                            <div
                              class="upload-results__search-row home__search-cell--bottom"
                              v-for="(key, index) in page.order_numbers"
                              :key="index"
                            >
                              <div
                                class="upload-results__search-cell upload-results__search-cell--left"
                              >
                                {{ key }}
                              </div>
                              <div class="upload-results__search-cell">
                                <template
                                  v-if="
                                    page.additional_info &&
                                    page.additional_info[key]
                                  "
                                >
                                  {{ page.additional_info[key] }}
                                </template>
                                <template v-else>--</template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </QExpansionItem>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>

      <!-- ПОИСК -->
      <div class="home__filter" v-if="isAuth">
        <p class="home__key-title">Введите ключ для поиска внутри докуметов</p>
        <QInput
          outlined
          v-model="form.search"
          class="home__key-input"
          @update:model-value="debouncedSearch"
        >
          <template v-slot:prepend>
            <iconSearch iconColor="#00A1FF"></iconSearch>
          </template>
          <template v-slot:append>
            <iconClear
              class="cursor-pointer"
              v-if="form.search"
              iconColor="#212121D9"
              @click="
                (form.dataSearch = {}), (form.search = ''), (searchMessage = '')
              "
            ></iconClear>
          </template>
        </QInput>
        <div class="home__filter-message">
          <span class="home__filter-message-text">
            {{ searchMessage }}
          </span>
        </div>
        <div
          class="home__search-list"
          v-if="form.search && Object.keys(form.dataSearch).length > 0"
        >
          <!-- <div
            class="home__search-item"
            v-for="document in form.dataSearch"
            :key="document.filename"
          >
            <span class="home__search-item-title">{{ document.filename }}</span>
            <div class="home__search-item-content">
              <div
                class="home__search-content-wtapper"
                v-for="(pages, index) in document.pages"
                :key="index"
              >
                <QExpansionItem>
                  <template v-slot:header>
                    <div class="home__search-item-text">
                      <span class="home__search-item-doc">
                        Страница {{ pages.page_number }};
                      </span>

                      <div v-if="pages.document_info.order_numbers.length > 0">
                        <span
                          class="home__search-item-doc home__search-item-doc--number"
                          v-for="(item, index) in pages.document_info
                            .order_numbers"
                          :key="index"
                          >№&nbsp;
                          {{ item }}
                        </span>
                      </div>

                      <div
                        v-if="pages.document_info.document_types.length > 0"
                        class="home__list-content"
                      >
                        <span class="home__search-item-doc">
                          {{
                            getDocTypesLabels(
                              pages.document_info.document_types
                            )
                          }}
                        </span>
                      </div>

                      <span v-else class="home__search-item-doc">
                        тип документа не определен
                      </span>
                    </div>
                  </template>
                  <div class="home__search-content">
                    <div class="home__search-table">
                      <div
                        class=""
                        v-for="(item, index) in pages.lines"
                        :key="index"
                      >
                        <div class="home__search-cell">
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </QExpansionItem>
              </div>
            </div>
          </div> -->
          <ul class="search-results__search-list">
            <li v-for="document in form.dataSearch" :key="document.filename">
              <div class="search-results__search-docs-list">
                <div class="search-results__search-item">
                  <div class="search-results__search-item-content">
                    <ul class="search-results__search-content-wtapper">
                      <li class="search-results__search-item-doc-wrapper">
                        <QExpansionItem>
                          <template v-slot:header>
                            <div class="search-results__search-export-wrapp">
                              <span class="search-results__search-item-doc">
                                {{ document.filename }}
                              </span>
                            </div>
                          </template>
                          <div
                            class="search-results__search-content"
                            v-for="(page, index) in document.pages"
                            :key="index"
                          >
                            <div class="search-results__search-item-doc">
                              Страница {{ page.page_number }}:
                              <template
                                v-if="
                                  page?.document_info?.document_types?.length >
                                  0
                                "
                              >
                                <span
                                  v-for="(type, index) in page.document_info
                                    .document_types"
                                  :key="index"
                                >
                                  {{ type }}
                                </span>
                              </template>
                              <span v-else> Тип документа не определен </span>
                              <template v-if="page.error">
                                <span>{{ page.error }}</span>
                              </template>
                              <template v-if="page.keyword">
                                <span
                                  >Ключевое слово: "{{ page.keyword }}"</span
                                >
                              </template>
                            </div>

                            <div
                              class="search-results__search-item-doc"
                              v-if="
                                page?.document_info?.additional_info?.length
                              "
                            >
                              Дополнительные параметры типа документа:
                              <span
                                v-for="(info, index) in page.document_info
                                  .additional_info"
                                :key="index"
                              >
                                {{ info }}
                              </span>
                            </div>

                            <div
                              class="search-results__search-table"
                              v-if="page?.document_info?.order_numbers?.length"
                            >
                              <div class="search-results__search-row">
                                <div class="search-results__search-cell">
                                  Ключи поиска
                                </div>
                              </div>

                              <div
                                class="search-results__search-row home__search-cell--bottom"
                                v-for="(key, index) in page.document_info
                                  .order_numbers"
                                :key="index"
                              >
                                <div class="search-results__search-cell">
                                  {{ key }}
                                </div>
                              </div>
                            </div>

                            <div
                              class="search-results__search-table"
                              style="margin-top: 24px"
                              v-if="page?.lines?.length"
                            >
                              <div class="search-results__search-row">
                                <div class="search-results__search-cell">
                                  Найденные данные
                                </div>
                              </div>

                              <div
                                class="search-results__search-row home__search-cell--bottom"
                                v-for="(line, index) in page.lines"
                                :key="index"
                              >
                                <div class="search-results__search-cell">
                                  {{ line }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </QExpansionItem>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="home__expansion">
          <div class="home__expansion-item-wrapper">
            <QExpansionItem>
              <template v-slot:header>
                <span class="home__expansion-item">Типы документов</span>
              </template>

              <div class="home__expansion-types-btns">
                <div
                  v-for="(type, index) in dataStore.allDocumentTypes"
                  :key="index"
                  class="home__expansion-type--btn"
                >
                  <template v-if="type.document_type">
                    <div
                      class="home__expansion-btn--blue"
                      @mouseover="hoverStates[index] = true"
                      @mouseleave="hoverStates[index] = false"
                      @click="showModalParams(type)"
                    >
                      <div class="home__expansion-top--wrapp">
                        <iconPaper
                          :iconColor="
                            hoverStates[index] ? '#ffffff' : '#075988'
                          "
                        />
                        {{ type.document_type }}
                      </div>
                      <div class="home__expansion-btn--close">
                        <iconClose
                          class="btn red"
                          @click.stop="showDeleteTypeModal(type)"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="home__expansion-btns-wrapp">
                <ButtonComponent
                  button-class="btn btn-primary"
                  text="Добавить тип"
                  @click="showAddTypeModal()"
                />
              </div>
            </QExpansionItem>
          </div>
        </div>
      </div>
    </div>
  </div>

  <QDialog v-model="deleteTypeModal">
    <QCard>
      <div class="modal">
        <div class="modal__header-wrapper">
          <p class="modal__title">
            Удалить
            <template v-if="deleteTypeProps?.document_type">
              тип документа {{ deleteTypeProps.document_type }}
            </template>
            ?
          </p>
          <iconClose class="btn gray-btn" @click="deleteTypeModal = false" />
        </div>

        <div class="modal__actions">
          <button class="btn btn-primary" @click="deleteTypeModal = false">
            <span>Отмена</span>
          </button>
          <button
            class="btn btn-primary"
            @click="deleteDocumentType()"
            :disabled="state.preloader"
          >
            <span>Удалить</span>
          </button>
        </div>
      </div>
    </QCard>
  </QDialog>

  <QDialog v-model="addTypeModal">
    <QCard>
      <div class="modal">
        <div class="modal__header-wrapper">
          <h2 class="modal__title">Добавить тип</h2>
          <iconClose class="btn gray-btn" @click="addTypeModal = false" />
        </div>
        <div class="modal__form">
          <div class="modal__field narrow">
            <QInput
              outlined
              v-model="form.newTypeForm.document_type"
              placeholder="Тип документа"
            ></QInput>
          </div>
        </div>

        <div class="modal__actions">
          <button class="btn btn-primary" @click="addTypeModal = false">
            <span>Отмена</span>
          </button>
          <button
            class="btn btn-primary"
            @click="addNewType()"
            :disabled="!form.newTypeForm.document_type || state.preloader"
          >
            <span>Добавить</span>
          </button>
        </div>
      </div>
    </QCard>
  </QDialog>

  <QDialog v-model="changeTypeModal">
    <QCard>
      <div class="modal">
        <div class="modal__header-wrapper">
          <h2 class="modal__title">Изменение параметров</h2>
          <iconClose class="btn gray-btn" @click="changeTypeModal = false" />
        </div>
        <div class="modal__form">
          <div class="modal__form-wrapp">
            <div class="modal__form-input--modified" v-if="!isEditTypeName">
              <span>{{ form.newParamsForm.document_type }}</span>
            </div>
            <div class="modal__row__icon--edit" v-if="!isEditTypeName">
              <iconEdit @click="isEditTypeName = true" class="gray-btn" />
            </div>

            <div class="modal__field narrow" v-if="isEditTypeName">
              <QInput
                outlined
                v-model="form.newParamsForm.document_type"
                placeholder="Наименование"
              ></QInput>
            </div>
            <div class="modal__row__icon--edit" v-if="isEditTypeName">
              <iconCheck @click="editNameType" class="gray-btn" />
            </div>
          </div>

          <div class="modal__field modal__row-wrapp narrow">
            <div
              class="modal__row--top"
              :class="{
                'modal__row--topNull': form.listParamsTypes.length === 0,
                'modal__row--bothConditions':
                  form.listParamsTypes.length === 0 && showFirstInput,
              }"
            >
              <span class="modal__field--title"
                >Параметры определения типа документов</span
              >
              <div class="icon-tooltip-wrapper">
                <iconInfo class="tooltip-icon" />
                <span class="tooltip-text"
                  >Укажите параметр по которому будет определяться тип
                  документа, например "Акт" или "Счет на оплату".</span
                >
              </div>
            </div>
            <template v-if="form.listParamsTypes.length > 0">
              <div
                v-for="(param, index) in form.listParamsTypes"
                :key="index"
                class="modal__row"
              >
                <span>
                  {{ param }}
                </span>

                <div class="modal__row__icon--close">
                  <iconClose
                    @click="deleteAdditionalParams(param)"
                    width="12"
                    height="12"
                    class="gray-btn"
                  />
                </div>
              </div>
            </template>

            <div class="modal__row--plus" v-if="showFirstInput">
              <div class="modal__field narrow">
                <QInput
                  v-if="showFirstInput"
                  borderless
                  v-model="newParameter"
                  placeholder="Введите новый параметр"
                ></QInput>
              </div>
              <div class="modal__row__icon--check">
                <iconCheck @click="addNewParams()" />
              </div>
            </div>

            <div
              v-if="!showFirstInput && form.listParamsTypes.length > 0"
              class="modal__row__icon--plus"
            >
              <iconPlus @click="toggleFirstInput()" />
            </div>

            <div
              v-if="!showFirstInput && form.listParamsTypes.length === 0"
              class="modal__row__icon--plus"
            >
              <iconPlus @click="toggleFirstInput()" />
            </div>

            <div
              v-if="showFirstInput && form.listParamsTypes.length === 0"
              class="modal__row__icon--plus"
            >
              <iconDeleteCross
                v-if="showFirstInput && form.listParamsTypes.length === 0"
                @click="handleFirstInput"
                style="transform: rotateZ(45deg)"
              />
              <iconPlus v-else @click="toggleFirstInput()" />
            </div>

            <div
              v-if="showFirstInput && form.listParamsTypes.length > 0"
              class="modal__row__icon--plus"
            >
              <iconDeleteCross
                v-if="showFirstInput && form.listParamsTypes.length > 0"
                @click="handleFirstInput"
                style="transform: rotateZ(45deg)"
              />
              <iconPlus v-else @click="toggleFirstInput()" />
            </div>
          </div>

          <div class="modal__field modal__row-wrapp narrow">
            <div
              class="modal__row--top"
              :class="{
                'modal__row--topNull': form.listContractNumbers.length === 0,
                'modal__row--bothConditions':
                  form.listContractNumbers.length === 0 && showSecondInput,
              }"
            >
              <span class="modal__field--title">Ключи поиска</span>
              <div class="icon-tooltip-wrapper">
                <iconInfo class="tooltip-icon" />
                <span class="tooltip-text"
                  >Укажите какие значения Вам необходимо найти в этом типе
                  документа, например "Номер" или "Сумма".</span
                >
              </div>
            </div>

            <template v-if="form.listContractNumbers.length > 0">
              <div
                v-for="(numb, index) in form.listContractNumbers"
                :key="index"
                class="modal__row"
              >
                <span v-for="(item, index) in numb" :key="index">
                  {{ item }}
                </span>

                <div class="modal__row__icon--close">
                  <iconClose
                    width="12"
                    height="12"
                    class="gray-btn"
                    @click="deleteContractNumbers(numb)"
                  />
                </div>
              </div>
            </template>

            <div class="modal__row--plus" v-if="showSecondInput">
              <div class="modal__field narrow">
                <QInput
                  v-if="showSecondInput"
                  class="modal__row-placeholder"
                  v-model="form.newParamsForm.contract_number"
                  placeholder="Введите новый параметр"
                  borderless
                ></QInput>
              </div>
              <div class="modal__row__icon--check">
                <iconCheck @click="addNewNumbers()" />
              </div>
            </div>

            <div
              v-if="!showSecondInput && form.listContractNumbers.length > 0"
              class="modal__row__icon--plus"
            >
              <iconPlus @click="toggleSecondInput()" />
            </div>

            <div
              v-if="!showSecondInput && form.listContractNumbers.length === 0"
              class="modal__row__icon--plus"
            >
              <iconPlus @click="toggleSecondInput()" />
            </div>

            <div
              v-if="showSecondInput && form.listContractNumbers.length === 0"
              class="modal__row__icon--plus"
            >
              <iconDeleteCross
                v-if="showSecondInput && form.listContractNumbers.length === 0"
                @click="handleSecondInput"
                style="transform: rotateZ(45deg)"
              />

              <iconPlus v-else @click="toggleSecondInput()" />
            </div>
            <div
              class="modal__row__icon--plus"
              v-if="showSecondInput && form.listContractNumbers.length > 0"
            >
              <iconDeleteCross
                v-if="showSecondInput && form.listContractNumbers.length > 0"
                @click="handleSecondInput"
                style="transform: rotateZ(45deg)"
              />
              <iconPlus v-else @click="toggleSecondInput()" />
            </div>
          </div>
        </div>
      </div>
    </QCard>
  </QDialog>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  QUploader,
  QSpinner,
  QList,
  QItem,
  QUploaderAddTrigger,
  QInput,
  QExpansionItem,
  QDialog,
  QCard,
} from 'quasar';
import {
  uploadFiles,
  searchText,
  addType,
  deleteType,
  addParams,
  addContractNumbers,
  deleteDocumentNumb,
  deleteParams,
  downloadExcel,
  editName,
} from '@/api/homePageService';
import iconClose from '@/components/icons/iconClose.vue';
import iconSearch from '@/components/icons/iconSearch.vue';
import iconClear from '@/components/icons/iconClear.vue';
import iconPaper from '@/components/icons/iconPaper.vue';
import iconPlus from '@/components/icons/iconPlus.vue';
import iconCheck from '@/components/icons/iconCheck.vue';
import iconExcel from '@/components/icons/iconExcel.vue';
import iconInfo from '@/components/icons/iconInfo.vue';
import iconEdit from '@/components/icons/iconEdit.vue';
import iconDeleteCross from '@/components/icons/iconDeleteCross.vue';
import { showError } from '@/utils/errorsInterceptor';
import type {
  ISearchDocument,
  IUserDocument,
  IDocumentTypes,
  IDocumentType,
  UserLoginResponse,
} from '@/types/common';
import { useAuthStore } from '@/store/authStore';
import { useDataStore } from '@/store/dataStore';
import ButtonComponent from '@/components/shared/ButtonComponent.vue';
import { getListDocumentTypes } from '@/api/homePageService';

interface stateType {
  preloader: boolean;
  progress: number;
  finishedRequsts: number;
  selectedDoc: IDocumentType | null;
}

interface IUploadSettings {
  uploadMaxOnTimeCount: number;
}

interface IDataSearch {
  filename: string;
  pages: ISearchDocument[];
}

interface IUserDocsData {
  filename: string;
  pages: IUserDocument[];
}

interface IUserSearchData {
  filename: string;
  pages: ISearchDocument[];
}

interface initDataType {
  search: string;
  newParamsForm: {
    document_type: string;
    contract_number: string;
    additional_parameters: string[];
    code: number;
  };
  newTypeForm: {
    document_type: string;
    additional_parameters: string[];
  };
  dataSearch: Record<string, IUserSearchData>;
  uploadDocsData: Record<string, IUserDocsData>;
  listDocumentTypes: IDocumentTypes;
  listParamsTypes: string[];
  listContractNumbers: string[];
}

const initData = () => ({
  search: '',
  newParamsForm: {
    document_type: '',
    contract_number: '',
    additional_parameters: [],
    code: 0,
  },
  newTypeForm: {
    document_type: '',
    additional_parameters: [],
  },
  dataSearch: {},
  uploadDocsData: {},
  listDocumentTypes: {},
  listParamsTypes: [],
  listContractNumbers: [],
});

export default defineComponent({
  name: 'HomePage',
  components: {
    QUploader,
    QSpinner,
    QList,
    QItem,
    QUploaderAddTrigger,
    QInput,
    QExpansionItem,
    QDialog,
    QCard,
    iconClose,
    iconSearch,
    iconClear,
    iconPaper,
    ButtonComponent,
    iconPlus,
    iconCheck,
    iconInfo,
    iconExcel,
    iconDeleteCross,
    iconEdit,
  },
  setup() {
    const authStore = useAuthStore();
    const dataStore = useDataStore();

    const isAuth = computed(
      (): UserLoginResponse | null => authStore.userStore
    );
    const filesData = ref<File[]>([]);
    const newParameter = ref<string>('');
    const settings: IUploadSettings = reactive<IUploadSettings>({
      uploadMaxOnTimeCount: 10,
    });

    const isMultipleUploading = computed(
      (): boolean => settings.uploadMaxOnTimeCount !== 1
    );

    const form = reactive<initDataType>(initData());
    const state = reactive<stateType>({
      preloader: false,
      progress: 0,
      finishedRequsts: 0,
      selectedDoc: null,
    });
    const searchMessage = ref<string>('');
    searchMessage.value = '';

    const rules = computed(() => ({
      search: {
        required,
      },
    }));
    const v$ = useVuelidate(rules, form);

    const deleteTypeModal = ref<boolean>(false);
    const deleteTypeProps = ref<IDocumentType | null>(null);

    const addTypeModal = ref<boolean>(false);
    const changeTypeModal = ref<boolean>(false);

    const isEditTypeName = ref<boolean>(false);

    const showFirstInput = ref<boolean>(false);
    const showSecondInput = ref<boolean>(false);
    const toggleFirstInput = () => {
      showFirstInput.value = !showFirstInput.value;
    };

    const toggleSecondInput = () => {
      showSecondInput.value = !showSecondInput.value;
    };

    const handleFirstInput = () => {
      showFirstInput.value = !showFirstInput.value;
      newParameter.value = '';
      form.newParamsForm.contract_number = '';
    };

    const handleSecondInput = () => {
      showSecondInput.value = !showSecondInput.value;
      newParameter.value = '';
      form.newParamsForm.contract_number = '';
    };

    const uploadRulesText = computed(
      (): string => '1 PDF файл, не более 2 страниц'
    );

    const onFilesAdded = async (files): Promise<void> => {
      if (files.length === 0) {
        console.warn('Нет выбранных файлов для отправки.');
        return;
      }

      form.uploadDocsData = {};
      state.progress = 25;
      state.finishedRequsts = 0;

      filesData.value = files;
      const formDataMap: FormData[] = [];
      filesData.value.forEach((file) => {
        const formData = new FormData();
        formData.append('files', file);
        formDataMap.push(formData);
      });
      state.preloader = true;

      for (const formData of formDataMap) {
        try {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          const response = await uploadFiles(formData, headers);
          if (response?.data) {
            response.data.pages.forEach((el) => {
              if (form.uploadDocsData[el.filename]?.pages) {
                form.uploadDocsData[el.filename]?.pages.push(el);
              } else {
                form.uploadDocsData[el.filename] = {
                  filename: el.filename,
                  pages: [el],
                };
              }
            });
          }
          state.finishedRequsts += 1;
          state.progress =
            (state.finishedRequsts / filesData.value.length) * 100;
        } catch (error) {
          showError(error);
          state.finishedRequsts += 1;
          state.progress =
            (state.finishedRequsts / filesData.value.length) * 100;
        }
      }
      state.preloader = false;
      setTimeout(() => {
        filesData.value = [];
        state.progress = 0;
        state.finishedRequsts = 0;
        dataStore.loadBalance();
        if (uploaderRef.value) {
          uploaderRef.value.reset();
        }
      }, 1000);
    };

    async function downloadFile(event): Promise<void> {
      event.stopPropagation();
      state.preloader = true;
      try {
        const response = await downloadExcel();
        state.preloader = false;
        if (response) {
          const blob_file = response.data;
          const file_url = URL.createObjectURL(blob_file);
          const link = document.createElement('a');
          link.href = file_url;
          link.setAttribute('download', 'export.xlsx');
          link.setAttribute('id', 'temporaryLinkFile');
          document.body.appendChild(link);
          link.click();
          (document.querySelector('#temporaryLinkFile') as any).remove();
          console.log(response);
        }
      } catch (error) {
        state.preloader = false;
        console.log(error);
        showError(error);
      }
    }

    let timeout;
    const debouncedSearch = (val: string | number | null) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleSearch(val);
      }, 800);
    };

    async function handleSearch(val: string | number | null) {
      form.dataSearch = {};
      if (!val) {
        searchMessage.value = '';
        return;
      }
      state.preloader = true;
      try {
        const response = await searchText(val);
        state.preloader = false;
        if (response) {
          if (Array.isArray(response.data?.results)) {
            searchMessage.value = '';
            response.data.results.forEach((el) => {
              if (form.dataSearch[el.filename]?.pages) {
                form.dataSearch[el.filename]?.pages.push(el);
              } else {
                form.dataSearch[el.filename] = {
                  filename: el.filename,
                  pages: [el],
                };
              }
            });
            console.log(form.dataSearch);
          } else if (response.data?.message) {
            searchMessage.value = response.data.message;
          }
        }
      } catch (error) {
        searchMessage.value = '';
        state.preloader = false;
        showError(error);
      }
    }

    const showAddTypeModal = (): void => {
      form.newTypeForm.document_type = '';
      addTypeModal.value = true;
    };

    async function addNewType() {
      const params = {
        document_type: form.newTypeForm.document_type,
      };
      state.preloader = true;
      try {
        const response = await addType(params);
        state.preloader = false;
        addTypeModal.value = false;
        if (response) {
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        showError(error);
      }
    }

    async function editNameType() {
      const params = {
        document_type: form.newParamsForm.document_type,
      };
      state.preloader = true;
      try {
        const response = await editName(form.newParamsForm.code, params);
        isEditTypeName.value = false;
        state.preloader = false;
        if (response) {
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        showError(error);
      }
    }

    async function addNewParams() {
      const trimmedParam = newParameter.value.trim();
      if (!trimmedParam) return;

      state.preloader = true;
      const params = {
        additional_params: [trimmedParam],
      };
      try {
        const response = await addParams(form.newParamsForm.code, params);
        state.preloader = false;
        if (response) {
          form.newParamsForm.additional_parameters.push(trimmedParam);
          newParameter.value = '';
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        showError(error);
      }
    }

    async function addNewNumbers() {
      state.preloader = true;
      const params = {
        contract_number: form.newParamsForm.contract_number,
      };
      try {
        const response = await addContractNumbers(
          form.newParamsForm.code,
          params
        );
        state.preloader = false;
        if (response) {
          loadListTypes();
          form.newParamsForm.contract_number = '';
        }
      } catch (error) {
        state.preloader = false;
        showError(error);
      }
    }

    const showModalParams = (doc: IDocumentType) => {
      state.selectedDoc = doc;
      showFirstInput.value = false;
      showSecondInput.value = false;
      form.newParamsForm.document_type = doc.document_type;
      form.newParamsForm.code = doc.code;
      form.newParamsForm.additional_parameters = [];
      form.listParamsTypes = doc.additional_parameters;
      form.listContractNumbers = doc.contract_numbers;
      changeTypeModal.value = true;
    };

    const showDeleteTypeModal = (type: IDocumentType) => {
      deleteTypeProps.value = type;
      deleteTypeModal.value = true;
    };

    async function deleteDocumentType() {
      if (!deleteTypeProps.value?.code) return;
      state.preloader = true;
      try {
        const response = await deleteType(deleteTypeProps.value.code);
        state.preloader = false;
        deleteTypeModal.value = false;
        if (response) {
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        deleteTypeModal.value = false;
        showError(error);
      }
    }

    const getDocTypesLabels = (types: number[]): string => {
      return types.join(', ');
    };

    const getNumbers = (numbers: string[]): string => {
      return numbers.join(', ');
    };

    async function loadListTypes() {
      try {
        const response = await getListDocumentTypes();
        if (response) {
          dataStore.allDocumentTypes = response.data.document_types;
          if (state.selectedDoc) {
            const doc = dataStore.allDocumentTypes.find(
              (d) => d.code === state?.selectedDoc?.code
            );
            if (doc) {
              form.listContractNumbers = JSON.parse(
                JSON.stringify(doc.contract_numbers)
              );
              form.listParamsTypes = JSON.parse(
                JSON.stringify(doc.additional_parameters)
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function deleteAdditionalParams(additParam: string) {
      state.preloader = true;
      const params = {
        additional_params: [additParam],
      };
      try {
        const response = await deleteParams(
          form.newParamsForm.code,
          params.additional_params
        );
        state.preloader = false;
        deleteTypeModal.value = false;
        if (response) {
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        deleteTypeModal.value = false;
        showError(error);
      }
    }

    async function deleteContractNumbers(param) {
      state.preloader = true;
      try {
        const response = await deleteDocumentNumb(
          form.newParamsForm.code,
          param.contract_number
        );
        state.preloader = false;
        deleteTypeModal.value = false;
        if (response) {
          loadListTypes();
        }
      } catch (error) {
        state.preloader = false;
        deleteTypeModal.value = false;
        showError(error);
      }
    }

    const uploaderRef = ref<QUploader | null>(null);
    const hoverStates = ref<boolean[]>([]);

    return {
      v$,
      form,
      state,
      iconClose,
      onFilesAdded,
      settings,
      isMultipleUploading,
      uploadRulesText,
      filesData,
      uploaderRef,
      handleSearch,
      addNewParams,
      addNewType,
      deleteDocumentType,
      dataStore,
      getDocTypesLabels,
      getNumbers,
      deleteTypeModal,
      showDeleteTypeModal,
      deleteTypeProps,
      addTypeModal,
      changeTypeModal,
      showAddTypeModal,
      isAuth,
      hoverStates,
      showModalParams,
      newParameter,
      addNewNumbers,
      showFirstInput,
      toggleFirstInput,
      showSecondInput,
      toggleSecondInput,
      deleteContractNumbers,
      deleteAdditionalParams,
      handleFirstInput,
      handleSecondInput,
      downloadFile,
      editNameType,
      isEditTypeName,
      searchMessage,
      debouncedSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/scss/pages/home-page.scss';
.modal {
  &__action--btn {
    width: 100%;
  }
}
.icon-tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-icon {
  cursor: pointer;
}
.icon-tooltip-wrapper .tooltip-text {
  width: 215px;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #000000;
  background-color: #023a5a;
  color: #fff;
  text-align: start;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  word-break: break-word;
  white-space: normal;
  font-size: 10px;
  left: -80px;
}
.icon-tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.q-item.q-item-type.row.no-wrap.q-item--clickable.q-link.cursor-pointer.q-focusable.q-hoverable {
  border-bottom: 1px solid var(--disabled);
}
</style>
