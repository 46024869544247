<template>
  <iconsWrapper :width="width" :height="height" :viewBox="viewBox">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 20.0049C10 20.0052 10 20.0055 10 20.0058C10.0001 20.6781 10.2444 21.3503 10.7329 21.8881C11.2167 22.4203 11.7988 23.0632 12.3692 23.6932C13.0559 24.4516 13.7256 25.1913 14.1866 25.6967C14.7418 26.3207 15.5525 26.665 16.4076 26.665H27.0461C28.6785 26.665 29.9889 25.3847 29.9889 23.8139H30V16.186C30 14.6045 28.6785 13.335 27.0572 13.335H16.4076C15.5636 13.335 14.7529 13.69 14.1866 14.314C13.3537 15.2392 11.799 16.9499 10.7329 18.1226C10.2444 18.6604 10.0001 19.3326 10 20.0049ZM27.0572 24.7499C27.5902 24.7499 28.0344 24.3303 28.0344 23.8032V16.186L28.0233 16.1753C28.0233 15.6588 27.5902 15.2285 27.0461 15.2285H16.4076C16.1299 15.2285 15.8634 15.3468 15.6746 15.5512L12.221 19.3598C11.8989 19.7256 11.8989 20.2635 12.221 20.6186L15.6746 24.4272C15.8523 24.6316 16.1299 24.7499 16.4076 24.7499H27.0572ZM22.7263 22.9317C23.1038 23.2975 23.759 23.319 24.1921 22.9317V22.9102C24.5919 22.5229 24.5919 21.8881 24.1921 21.5008L22.6152 19.9838L24.181 18.4668C24.3698 18.284 24.4808 18.0365 24.4808 17.7675C24.4808 17.4986 24.3698 17.2511 24.181 17.0682C23.7923 16.6917 23.1149 16.6917 22.7263 17.0682L21.1605 18.5852L19.5947 17.0575C19.206 16.6809 18.5286 16.6809 18.1399 17.0575C17.7401 17.4448 17.7401 18.0795 18.1399 18.4668L19.7057 19.9946L18.1288 21.5223C17.7401 21.9204 17.7401 22.5444 18.1288 22.9425C18.3287 23.1361 18.573 23.2329 18.8617 23.2329C19.1283 23.2329 19.3948 23.1254 19.5947 22.9425L21.1605 21.4147L22.7263 22.9317Z"
      :fill="iconColor"
      fill-opacity="0.85"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'iconClear',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    viewBox: {
      type: String,
      default: '0 0 40 40',
    },
  },
};
</script>
