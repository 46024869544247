import axios from 'axios';
import {
  ApiResponse,
  messageType,
  ISearchDocument,
  UserDocumentsResponse,
  IDocumentTypes,
  UserDocumentTypesResponse,
  IUserDocument,
  IDocumentKey,
} from '@/types/common';

export const uploadFiles = (
  formData: FormData,
  headers: object
): ApiResponse<{ pages: IUserDocument[] }> => {
  return axios.post('/documents/upload/', formData, {
    headers,
  });
};

/* export const getLoadingStatusByName = (fileName): ApiResponse<any> => {
  return axios.get(`/documents/file_status/${fileName}`);
}; */

export const searchText = (
  keyword
): ApiResponse<{
  results?: ISearchDocument[];
  message?: string;
}> => {
  return axios.get(`/documents/search_text/?keyword=${keyword}`);
};

//Получение данных пользователя
export const loadDocumentsData = (): ApiResponse<{
  pages: IUserDocument[];
}> => {
  return axios.get('/documents/user_data/');
};

//Получение списка типов документов
export const getListDocumentTypes =
  (): ApiResponse<UserDocumentTypesResponse> => {
    return axios.get('/documents/document_types/');
  };

//Добавить тип документа
export const addType = (params): ApiResponse<any> => {
  return axios.post('/documents/document_types/', params);
};

//Добавление нового параметра типа документа
export const addParams = (code, params): ApiResponse<any> => {
  return axios.post(`/documents/document_types/${code}/add_params/`, params);
};

//Добавление номера договора к типу документа
export const addContractNumbers = (code, params): ApiResponse<any> => {
  return axios.post(
    `/documents/document_types/${code}/contract_numbers/`,
    params
  );
};

//Удалить тип документа
export const deleteType = (type_id): ApiResponse<any> => {
  //return axios.delete(`/documents/delete_document_type/${id}`);
  return axios.delete(`/documents/document_types/${type_id}`);
};

/* //Получение списка ключей документов
export const getListDocumentKeys = (): ApiResponse<any> => {
  return axios.get('/documents/document_contracts_get/');
};

//Добавить ключ документа
export const addDocumentKey = (params): ApiResponse<IDocumentKey[]> => {
  return axios.post('/documents/document_contract_post/', params);
}; */

//Удалить нового параметра типа документа
export const deleteParams = (code, params): ApiResponse<any> => {
  return axios.request({
    url: `/documents/document_types/${code}/remove_params/`,
    method: 'DELETE',
    data: {
      additional_params: params,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

//Удалить номера договора
export const deleteDocumentNumb = (code, contract_number): ApiResponse<any> => {
  return axios.delete(
    `/documents/document_types/${code}/contract_numbers/${contract_number}`
  );
};
export const downloadExcel = () => {
  return axios.post('documents/download_xml/', null, {
    responseType: 'blob',
  });
};

//редактироование названия типа документа
export const editName = (code, params): ApiResponse<any> => {
  return axios.put(`/documents/document_types/${code}`, params);
};
