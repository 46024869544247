export function isAuth({ user }) {
  if (!user.isAuth()) {
    return {
      isSuccess: false,
      next: 'HomePage',
    };
  }
  return {
    isSuccess: true,
  };
}
/* 

export function isAdmin({ user }) {
  if (!user.isAdmin()) {
    return {
      isSuccess: false,
      next: 'HomePage',
    };
  }
  return {
    isSuccess: true,
  };
}

export function isCustomer({ user }) {
  if (!user.isCustomer()) {
    return {
      isSuccess: false,
      next: 'HomePage',
    };
  }
  return {
    isSuccess: true,
  };
} */
