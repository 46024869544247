import type { UserLoginResponse, userType } from '@/types/common';

export interface IAuth {
  data: userType | null;
  isAuth(): boolean;
  isAdmin(): boolean;
  isCustomer(): boolean;
}

export default class Auth implements IAuth {
  data: userType | null = null;

  constructor(user: userType | null) {
    this.data = user;
  }

  setUser(user: userType | null) {
    this.data = user;
  }

  isAuth(): boolean {
    if (this.data?.token) {
      return true;
    }
    return false;
  }

  isAdmin(): boolean {
    const isAdmin = this.data?.role === 'admin';
    if (!isAdmin) {
      return false;
    }
    return true;
  }
  isCustomer(): boolean {
    const isCustomer = this.data?.role === 'manager';
    if (!isCustomer) {
      return false;
    }
    return true;
  }
}
