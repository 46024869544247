<template>
  <QDialog v-model="state.showSnackbar" @hide="afterLeave">
    <QCard style="max-width: 80vw">
      <div class="modal" :class="state.color">
        <p class="modal__title">{{ state.message }}</p>
        <div class="modal__actions">
          <button class="btn btn-primary" @click="close">Закрыть</button>
        </div>
      </div>
    </QCard>
  </QDialog>
</template>

<script lang="ts">
import { defineComponent, reactive, onUnmounted } from 'vue';
import { snackbarType } from '@/types/common';
import iconClose from '@/components/icons/iconClose.vue';
import { QDialog, QCard } from 'quasar';

const initState = (): snackbarType => ({
  showSnackbar: false,
  message: 'Ошибка, введите логин и пароль',
  color: 'indigo',
});

export default defineComponent({
  name: 'SnackbarComponent',
  components: {
    iconClose,
    QDialog,
    QCard,
  },

  setup() {
    const state: snackbarType = reactive(initState());

    const showSnackbar = (evt) => {
      const params = Object.assign(initState(), evt.detail);
      params.showSnackbar = true;
      Object.keys(state).forEach((key) => (state[key] = params[key]));
    };

    window.addEventListener('ShowSnackbar', showSnackbar, true);

    onUnmounted(() => {
      window.removeEventListener('ShowSnackbar', showSnackbar, true);
    });

    const close = () => {
      state.showSnackbar = false;
    };

    const afterLeave = () => {
      state.showSnackbar = false;
    };
    return { state, close, afterLeave };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #fef2f2;
  color: #991b1b;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}
</style>
