<template>
  <header class="header">
    <div class="header__content app-container">
      <router-link :to="{ name: 'HomePage' }">
        <img
          class="header__logo-desktop"
          alt="logo-decktop"
          src="../../assets/logo_1.svg"
        />

        <img
          class="header__logo-mobile"
          alt="logo-mobile"
          src="../../assets/logo-mobile.png"
        />
      </router-link>

      <div class="header__btn-group" v-if="!isAuth">
        <button class="btn btn-plain" @click="showLoginModal()">Войти</button>
        <button class="btn btn-primary" @click="showRegistrationModal()">
          Регистрация
        </button>
      </div>
      <div class="header__profile-wrap" v-else>
        <span class="header__balance" v-if="!isProfilePage">
          Баланс:
          <span class="header__balance-count">
            {{ dataStore.userBalance }}&nbsp;₽</span
          >
        </span>
        <router-link :to="{ name: 'ProfilePage' }">
          <div class="header__profile">
            <img
              class="header__profile--icon"
              alt="profile"
              src="../../assets/profile-circle.png"
            />
          </div>
        </router-link>
        <div class="header__logout">
          <img
            @click="logout()"
            class="header__profile--icon"
            alt="profile"
            src="../../assets/logout.png"
          />
        </div>
      </div>
    </div>
  </header>
  <LoginModal
    ref="loginModalRef"
    @openRegistration="showRegistrationModal()"
    @resetPass="showResetPassModal()"
  ></LoginModal>
  <RegistrationModal
    ref="registrationModalRef"
    @openAuthModal="showLoginModal()"
  ></RegistrationModal>
  <ResetPasswordModal
    ref="resetPasswordRef"
    @openAuthModal="showLoginModal()"
  ></ResetPasswordModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import LoginModal, { modalType } from './LoginModal.vue';
import RegistrationModal from './RegistrationModal.vue';
import ResetPasswordModal from './ResetPasswordModal.vue';
import { useAuthStore } from '@/store/authStore';
import { useDataStore } from '@/store/dataStore';
import type { UserLoginResponse } from '@/types/common';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'HeaderComponent',
  components: { LoginModal, RegistrationModal, ResetPasswordModal },
  setup() {
    const dataStore = useDataStore();
    const route = useRoute();
    const router = useRouter();
    const store = useAuthStore();
    const isAuth = computed((): UserLoginResponse | null => store.userStore);

    const isProfilePage = computed(() => {
      return route.path === '/profile';
    });
    const showLoginModal = () => {
      if (loginModalRef.value) {
        loginModalRef.value.isOpen = true;
      }
    };

    const showRegistrationModal = () => {
      router.push({ name: 'HomePage' });
      store.logout();
      if (registrationModalRef.value) {
        registrationModalRef.value.isOpen = true;
      }
    };
    const showResetPassModal = () => {
      router.push({ name: 'HomePage' });
      store.logout();
      if (resetPasswordRef.value) {
        resetPasswordRef.value.isOpen = true;
      }
    };

    const logout = () => {
      router.push({ name: 'HomePage' });
      store.logout();
    };

    const loginModalRef = ref<modalType | null>(null);
    const registrationModalRef = ref<modalType | null>(null);
    const resetPasswordRef = ref<modalType | null>(null);
    return {
      showRegistrationModal,
      showResetPassModal,
      loginModalRef,
      registrationModalRef,
      resetPasswordRef,
      showLoginModal,
      isAuth,
      isProfilePage,
      logout,
      dataStore,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  background-color: var(--background-1);
  &__content {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 8px;
    user-select: none;
    margin: 0;
    &-mobile {
      display: none;
      @media (max-width: 768px) {
        display: inline-block;
      }
    }

    &-desktop {
      display: inline-block;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  &__hero {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 20px;
  }
  &__btn-group {
    display: flex;
    height: 100%;
    align-items: center;
    column-gap: 16px;
  }
  &__balance {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  &__balance-count {
    color: var(--primary);
  }
  &__profile-wrap {
    display: flex;
    column-gap: 30px;
  }
  &__profile {
    cursor: pointer;
    height: 32px;
    width: 32px;
    &:hover {
      opacity: 0.7;
    }
  }

  &__profile--icon {
    height: 32px;
    width: 32px;
  }
  &__logout {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
