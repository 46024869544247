<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-class"
  >
    <path
      d="M12.2382 10.0938L5.32007 8.875V17.8806C5.32015 17.9785 5.3395 18.0753 5.37702 18.1657C5.41453 18.256 5.46948 18.3381 5.53872 18.4072C5.60795 18.4764 5.69013 18.5312 5.78055 18.5685C5.87097 18.6059 5.96786 18.6251 6.06569 18.625H18.0032C18.1011 18.6252 18.1982 18.6062 18.2887 18.5689C18.3793 18.5316 18.4616 18.4768 18.531 18.4077C18.6003 18.3385 18.6554 18.2564 18.693 18.166C18.7306 18.0755 18.75 17.9786 18.7501 17.8806V14.5625L12.2382 10.0938Z"
      fill="#185C37"
    />
    <path
      d="M12.2382 2.375H6.06569C5.96786 2.37492 5.87097 2.39411 5.78055 2.43147C5.69013 2.46883 5.60795 2.52364 5.53872 2.59276C5.46948 2.66189 5.41453 2.74397 5.37702 2.83432C5.3395 2.92468 5.32015 3.02154 5.32007 3.11938V6.4375L12.2382 10.5L15.9013 11.7188L18.7501 10.5V6.4375L12.2382 2.375Z"
      fill="#21A366"
    />
    <path d="M5.32007 6.4375H12.2382V10.5H5.32007V6.4375Z" fill="#107C41" />
    <path
      opacity="0.1"
      d="M10.2713 5.625H5.32007V15.7812H10.2713C10.4686 15.7803 10.6576 15.7016 10.7973 15.5623C10.9369 15.4229 11.0161 15.2342 11.0176 15.0369V6.36937C11.0161 6.17209 10.9369 5.98333 10.7973 5.84399C10.6576 5.70466 10.4686 5.62598 10.2713 5.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.86444 6.03125H5.32007V16.1875H9.86444C10.0617 16.1865 10.2507 16.1078 10.3904 15.9685C10.5301 15.8292 10.6092 15.6404 10.6107 15.4431V6.77562C10.6092 6.57834 10.5301 6.38958 10.3904 6.25024C10.2507 6.11091 10.0617 6.03223 9.86444 6.03125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.86444 6.03125H5.32007V15.375H9.86444C10.0617 15.374 10.2507 15.2953 10.3904 15.156C10.5301 15.0167 10.6092 14.8279 10.6107 14.6306V6.77562C10.6092 6.57834 10.5301 6.38958 10.3904 6.25024C10.2507 6.11091 10.0617 6.03223 9.86444 6.03125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.45757 6.03125H5.32007V15.375H9.45757C9.65486 15.374 9.84382 15.2953 9.9835 15.156C10.1232 15.0167 10.2023 14.8279 10.2038 14.6306V6.77562C10.2023 6.57834 10.1232 6.38958 9.9835 6.25024C9.84382 6.11091 9.65486 6.03223 9.45757 6.03125Z"
      fill="black"
    />
    <path
      d="M1.99625 6.03125H9.4575C9.65514 6.03109 9.84477 6.1094 9.9847 6.24898C10.1246 6.38856 10.2034 6.57798 10.2037 6.77563V14.2244C10.2034 14.422 10.1246 14.6114 9.9847 14.751C9.84477 14.8906 9.65514 14.9689 9.4575 14.9688H1.99625C1.89836 14.9689 1.8014 14.9498 1.71091 14.9125C1.62042 14.8751 1.53817 14.8203 1.46887 14.7512C1.39956 14.6821 1.34456 14.6 1.30701 14.5096C1.26945 14.4192 1.25008 14.3223 1.25 14.2244V6.77563C1.25008 6.67774 1.26945 6.58083 1.30701 6.49043C1.34456 6.40003 1.39956 6.31792 1.46887 6.24879C1.53817 6.17966 1.62042 6.12487 1.71091 6.08754C1.8014 6.05021 1.89836 6.03109 1.99625 6.03125Z"
      fill="url(#paint0_linear_997_548)"
    />
    <path
      d="M3.5625 12.9206L5.13188 10.4931L3.69437 8.07935H4.84875L5.63312 9.62497C5.70562 9.77122 5.75813 9.87997 5.78188 9.95247H5.7925C5.84375 9.83497 5.89812 9.72185 5.955 9.61122L6.79375 8.08185H7.85625L6.38188 10.4818L7.89375 12.9225H6.76313L5.85688 11.2281C5.8148 11.1552 5.77904 11.0789 5.75 11H5.735C5.70857 11.0769 5.67334 11.1505 5.63 11.2193L4.69688 12.9206H3.5625Z"
      fill="white"
    />
    <path
      d="M18.0037 2.375H12.238V6.4375H18.7499V3.11938C18.7498 3.02149 18.7305 2.92458 18.6929 2.83418C18.6553 2.74378 18.6003 2.66167 18.531 2.59254C18.4617 2.52341 18.3795 2.46862 18.289 2.43129C18.1985 2.39396 18.1015 2.37484 18.0037 2.375Z"
      fill="#33C481"
    />
    <path d="M12.238 10.5H18.7499V14.5625H12.238V10.5Z" fill="#107C41" />
    <defs>
      <linearGradient
        id="paint0_linear_997_548"
        x1="2.80875"
        y1="5.44625"
        x2="8.645"
        y2="15.5538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#18884F" />
        <stop offset="0.5" stop-color="#117E43" />
        <stop offset="1" stop-color="#0B6631" />
      </linearGradient>
    </defs>
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'iconExcel',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewBox: {
      type: String,
      default: '0 0 18 18',
    },
  },
};
</script>

<style scoped>
.icon-class {
  border-color: #ffffff;
  color: #ffffff;
}
/* .icon-class:hover {
  background-color: #ffffff;
  border-color: #075988;
  color: #075988;
} */
</style>
