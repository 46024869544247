import { defineStore } from 'pinia';
import type { UserLoginResponse, TokenType } from '@/types/common';
import { ref, computed } from 'vue';
import { userClassApp } from '@/utils/getAuth';

export const useAuthStore = defineStore('authStore', () => {
  const userStore = ref<any | null>(null);

  const getUser = computed((): UserLoginResponse | null => userStore.value);

  function setUser(userData: UserLoginResponse | null, stayLoggined?: boolean) {
    userStore.value = userData;
    if (userData) {
      if (stayLoggined) {
        localStorage.setItem('authDocs', JSON.stringify(userData));
      }
      userClassApp.setUser(userData);
    } else {
      localStorage.removeItem('authDocs');
      userClassApp.setUser(null);
    }
  }

  function logout() {
    setUser(null);
    userClassApp.setUser(null);
    localStorage.removeItem('authDocs');
  }
  function $reset() {
    console.log('reset');
  }
  return {
    userStore,
    getUser,
    setUser,
    logout,
    $reset,
  };
});
