import type { errorsObjectType, TokenType } from '@/types/common';
import { EDocumentTypes } from '@/types/common';

export const defaultToken: TokenType = '';

export const errorsConst: errorsObjectType = Object.freeze({
  non_field_errors: [],
});

export const documentTypesConst = Object.freeze([
  { key: EDocumentTypes.act, label: 'Акт' },
  {
    key: EDocumentTypes.act_acceptance_of_goods,
    label: 'Акт о приемке товаров',
  },
  {
    key: EDocumentTypes.act_warehouse,
    label: 'Акт приема-передачи товара на складе Покупателя',
  },
  {
    key: EDocumentTypes.invoice_form_4,
    label: 'Транспортная накладная (форма)',
  },
  {
    key: EDocumentTypes.invoice_form_5,
    label: 'Транспортная накладная',
  },
  {
    key: EDocumentTypes.invoice_form_6,
    label: 'Транспортная накладная (форма)',
  },
  {
    key: EDocumentTypes.invoice_form_7,
    label: 'Транспортная накладная',
  },
  {
    key: EDocumentTypes.packing_list,
    label: 'Упаковочный лист',
  },
  {
    key: EDocumentTypes.invoice_form_9,
    label: 'Транспортная накладная',
  },
  {
    key: EDocumentTypes.appendix_invoice_10,
    label: 'ПРИЛОЖЕНИЕ К ТОВАРНОЙ НАКЛАДНОЙ НАКЛАДНОЙ',
  },
  {
    key: EDocumentTypes.appendix_invoice_11,
    label: 'ПРИЛОЖЕНИЕ К ТОВАРНОЙ НАКЛАДНОЙ НАКЛАДНОЙ',
  },
]);
