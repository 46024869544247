<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-class"
  >
    <path
      d="M7.66812 15.6581L0.673169 8.80124C0.220163 8.36101 0.220163 7.6273 0.673169 7.18707L7.66812 0.330172C8.12112 -0.110057 8.86725 -0.110057 9.32026 0.330172C9.75994 0.783741 9.75994 1.50412 9.32026 1.94434L3.15138 8.00082L9.32026 14.044C9.77326 14.4842 9.78659 15.2046 9.33358 15.6581C8.88058 16.1117 8.14777 16.1117 7.68144 15.6715C7.68144 15.6715 7.68144 15.6715 7.66812 15.6581Z"
      :fill="iconColor"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'iconArrowLeft',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 10,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    viewBox: {
      type: String,
      default: '0 0 10 16',
    },
  },
};
</script>

<style scoped></style>
