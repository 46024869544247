export default function middlewareHandler(context, middleware) {
  let redirectIfRejected = null;
  let isResolved = false;

  middleware.forEach((item) => {
    const middlewareResult = item({ ...context });
    if (middlewareResult.isSuccess === true) {
      isResolved = true;
    } else {
      redirectIfRejected = middlewareResult.next;
    }
  });

  if (isResolved) {
    return context.next();
  }
  return context.next({
    name: redirectIfRejected,
  });
}
