<template>
  <QDialog v-model="isOpen" @hide="afterLeave">
    <QCard>
      <div class="modal" v-show="step === 0">
        <div class="modal__header-wrapper">
          <h2 class="modal__title">Регистрация</h2>
          <iconClose class="btn gray-btn" @click="close()" />
        </div>
        <div class="modal__field narrow">
          <label class="modal__label">Email</label>
          <QInput
            ref="emailRef"
            outlined
            v-model="form.firstStep.email"
            type="email"
            class="home__key-input"
            @blur="v$.email.$touch"
            @input="v$.email.$touch"
          ></QInput>
          <span
            class="modal__field-error"
            v-if="v$.email.$dirty && v$.email.$invalid"
          >
            Неверный формат
          </span>
        </div>
        <div class="modal__field narrow">
          <label class="modal__label">Пароль</label>
          <QInput
            outlined
            v-model="form.firstStep.pass"
            :type="!isShowPass1 ? 'password' : 'text'"
            class="home__key-input"
            @blur="v$.pass.$touch"
            @input="v$.pass.$touch"
          >
            <template v-slot:append>
              <iconEye
                @click="isShowPass1 = !isShowPass1"
                :class="{ 'icon-disabled': !isShowPass1 }"
              ></iconEye>
            </template>
          </QInput>
          <span
            class="modal__field-error"
            v-if="v$.pass.$dirty && !form.firstStep.pass"
            >Введите пароль</span
          >
        </div>
        <div class="modal__field narrow">
          <label class="modal__label">Подтвердите пароль</label>
          <QInput
            outlined
            v-model="form.firstStep.repeatPass"
            :type="!isShowPass2 ? 'password' : 'text'"
            class="home__key-input"
            @blur="v$.pass.$touch"
            @input="v$.pass.$touch"
          >
            <template v-slot:append>
              <iconEye
                @click="isShowPass2 = !isShowPass2"
                :class="{ 'icon-disabled': !isShowPass2 }"
              ></iconEye>
            </template>
          </QInput>
          <span
            class="modal__field-error"
            v-if="
              v$.repeatPass.$dirty &&
              form.firstStep.pass !== form.firstStep.repeatPass
            "
            >Пароли должны совпадать</span
          >
        </div>
        <div class="modal__field">
          <div class="column">
            <button
              class="btn btn-primary"
              :disabled="
                v$.email.$invalid ||
                !form.firstStep.pass ||
                form.firstStep.pass !== form.firstStep.repeatPass ||
                state.preloader
              "
              @click="submitFirst"
            >
              Зарегистрироватья
            </button>
          </div>
        </div>
      </div>

      <div class="modal" v-show="step === 1">
        <div class="modal__header-link-wrapper">
          <p class="modal__back link" @click="back()">
            <iconArrowLeft></iconArrowLeft>&nbsp;Изменить
            {{ form.type === 'email' ? 'email' : 'номер телефона' }}
          </p>

          <iconClose class="btn gray-btn" @click="close()" />
        </div>
        <h2 class="modal__title">
          Введите код из {{ form.type === 'email' ? 'письма' : 'СМС' }}
        </h2>
        <div class="modal__field">
          <div class="column">
            <div class="modal__code-wrapper h-middle" @keydown="handleKeyDown">
              <div
                class="modal__code-input"
                v-for="(field, index) in form.emailCode"
                :key="index"
              >
                <QInput
                  ref="emailCodeRef"
                  outlined
                  v-model="form.emailCode[index]"
                  @update:model-value="(val) => emailCodeChanged(val, index)"
                  @paste="handlePaste($event, index)"
                  @focus="setCurrentInputIndex(index)"
                  maxlength="1"
                ></QInput>
              </div>
            </div>
            <span class="modal__timer">
              <span v-if="form.emailCodeTimer !== 0">
                Через <span class="link">{{ form.emailCodeTimer }} сек</span>
                можно получить повторно
              </span>
              <span v-if="form.emailCodeTimer === 0"
                >Не пришел код?
                <span class="link" @click="sendCodeAgain()"
                  >Запросить повторно</span
                ></span
              >
            </span>
          </div>
          <span
            class="modal__field-error"
            v-if="v$.email.$dirty && v$.email.$invalid"
          >
            Неверный формат
          </span>
        </div>
        <div class="modal__field">
          <div class="column">
            <button
              class="btn btn-primary"
              :disabled="!isEmailCodeValid || state.preloader"
              @click="submitSecond"
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>

      <div class="modal" v-show="step === 2">
        <div class="modal__header-link-wrapper">
          <span></span>
          <iconClose class="btn gray-btn" @click="close()" />
        </div>
        <div class="modal__success">
          <div><iconCheck :width="32" :height="32"></iconCheck></div>
          Email успешно подтвержден.<br />
          Теперь вы можете пользоваться сервисом.
        </div>
        <div class="modal__field">
          <div class="column">
            <button class="btn btn-primary" @click="openAuthModal">
              Авторизоваться
            </button>
          </div>
        </div>
      </div>
    </QCard>
  </QDialog>
</template>

<script lang="ts">
export interface modalType {
  isOpen: boolean;
}

interface IState {
  preloader: boolean;
}

interface IForm {
  firstStep: IinitFirstStepData;
  emailCode: Record<number, string>;
  emailCodeTimer: number;
  type: 'email' | 'phone';
}

interface IinitFirstStepData {
  email: string;
  pass: string;
  repeatPass: string;
  stayLoggined: boolean;
}

const initFirstStepData = () => ({
  email: '',
  pass: '',
  repeatPass: '',
  stayLoggined: false,
});

const initEmailCode = () => ({
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
});

import { defineComponent, ref, reactive, computed } from 'vue';
import iconClose from '@/components/icons/iconClose.vue';
import { QDialog, QCard, QInput, QCheckbox } from 'quasar';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import iconArrowLeft from '@/components/icons/iconArrowLeft.vue';
import iconCheck from '@/components/icons/iconCheck.vue';
import iconEye from '@/components/icons/iconEye.vue';
import {
  userRegistration,
  requestConfirmationCode,
  verifyRegistration,
} from '@/api/authService';
import { showError } from '@/utils/errorsInterceptor';

export default defineComponent({
  name: 'RegistrationModal',
  components: {
    iconClose,
    iconArrowLeft,
    iconCheck,
    iconEye,
    QDialog,
    QCard,
    QInput,
    QCheckbox,
  },
  emits: ['close', 'openAuthModal'],
  props: {},

  setup(props, { emit }) {
    const isShowPass1 = ref(false);
    const isShowPass2 = ref(false);
    const step = ref(0);
    const isOpen = ref(false);
    const state: IState = reactive({
      preloader: false,
    });
    const timer = ref(0);
    const currentInputIndex = ref(0); // Индекс текущего инпута с фокусом

    const form = reactive<IForm>({
      firstStep: initFirstStepData(),
      emailCode: initEmailCode(),
      emailCodeTimer: 0,
      type: 'email',
    });

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?\d{10,15}$/;

    const isEmailOrPhone = helpers.withParams(
      { type: 'isEmailOrPhone' },
      (value: string) => {
        return emailPattern.test(value);
        //return emailPattern.test(value) || phonePattern.test(value);
      }
    );

    const isRepeatPass = helpers.withParams(
      { type: 'isRepeatPass' },
      (value: string) => {
        return value === form.firstStep.pass;
      }
    );

    const rules = computed(() => ({
      email: {
        required,
        isEmailOrPhone,
      },
      pass: {
        required,
      },
      repeatPass: {
        required,
        isRepeatPass,
      },
    }));
    const v$ = useVuelidate(rules, form.firstStep);

    const isEmailCodeValid = computed((): boolean => {
      let res = true;
      for (let key in form.emailCode) {
        if (form.emailCode[key] === '') {
          res = false;
        }
      }
      return res;
    });

    const emailCodeChanged = (value, index) => {
      if (value !== '' && value !== null) {
        if (emailCodeRef.value[+index + 1]) {
          emailCodeRef.value[+index + 1].focus();
        }
      }
    };

    const handlePaste = (event, index) => {
      // Предотвращаем обычное поведение вставки
      event.preventDefault();
      // Получаем вставляемые данные
      const pastedData = event.clipboardData.getData('text/plain').slice(0, 6); // Ограничиваем длину 6 символами
      // Обновляем значения форм
      for (let key in form.emailCode) {
        form.emailCode[key] = pastedData[key] || '';
      }
    };

    const setCurrentInputIndex = (index) => {
      currentInputIndex.value = index; // Устанавливаем текущий индекс инпута
    };
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        event.preventDefault();
        for (let key in form.emailCode) {
          form.emailCode[key] = '';
        }
      }
    };

    const initEmailTimer = () => {
      form.emailCodeTimer = 60;
      timer.value = setInterval(() => {
        if (form.emailCodeTimer < 1) {
          clearInterval(timer.value);
          return;
        }
        form.emailCodeTimer = form.emailCodeTimer - 1;
      }, 1000);
    };

    const submitFirst = async (): Promise<void> => {
      const params = {
        contact_info: form.firstStep.email,
        password: form.firstStep.pass,
      };
      state.preloader = true;
      try {
        const response = await userRegistration(params);
        state.preloader = false;
        if (response && response.data) {
          /* form.type = emailPattern.test(form.firstStep.email)
            ? 'email'
            : 'phone'; */
          form.type = 'email';
          step.value = 1;
          if (form.emailCodeTimer === 0) {
            initEmailTimer();
          }
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    };

    const sendCodeAgain = async (): Promise<void> => {
      const params = {
        contact_info: form.firstStep.email,
      };
      state.preloader = true;
      try {
        const response = await requestConfirmationCode(params);
        state.preloader = false;
        if (response && response.data) {
          if (form.emailCodeTimer === 0) {
            initEmailTimer();
          }
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    };

    const back = (): void => {
      step.value = 0;
      if (emailRef.value) {
        emailRef.value.focus();
      }
    };

    const submitSecond = async (): Promise<void> => {
      const params = {
        contact_info: form.firstStep.email,
        confirmation_code: '',
      };
      for (let key in form.emailCode) {
        params.confirmation_code =
          params.confirmation_code + form.emailCode[key];
      }
      state.preloader = true;
      try {
        const response = await verifyRegistration(params);
        state.preloader = false;
        if (response && response.data) {
          clearInterval(timer.value);
          step.value = 2;
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    };

    const close = () => {
      reset();
      isOpen.value = false;
    };

    const afterLeave = () => {
      reset();
      emit('close');
    };

    const reset = () => {
      clearInterval(timer.value);
      form.firstStep = initFirstStepData();
      form.emailCode = initEmailCode();
      step.value = 0;
    };

    const openAuthModal = (): void => {
      close();
      emit('openAuthModal');
    };

    const emailRef = ref<any | null>(null);
    const emailCodeRef = ref<any | null>(null);

    return {
      isOpen,
      submitFirst,
      close,
      afterLeave,
      form,
      v$,
      state,
      step,
      submitSecond,
      isEmailCodeValid,
      emailCodeChanged,
      handlePaste,
      emailCodeRef,
      initEmailTimer,
      emailRef,
      back,
      isShowPass1,
      isShowPass2,
      sendCodeAgain,
      openAuthModal,
      currentInputIndex,
      setCurrentInputIndex,
      handleKeyDown,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  padding: 48px;
  display: flex;
  flex-direction: column;
  &__back {
    display: flex;
    column-gap: 8px;
    margin-left: -19px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 1px;
  }
  &__success {
    display: flex;
    column-gap: 16px;
    font-size: 16px;
  }
  &__field {
    margin-top: 24px;
    min-width: 384px;
    position: relative;
  }
  &__field-error {
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: var(--typography-error);
  }
  &__stay-loggined {
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    align-items: center;
  }
  &__no-accaunt {
    margin: 16px auto 0px auto;
  }
  &__code-input {
    width: 37px;
  }
  &__code-wrapper {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 14px;
    justify-content: center;
  }
  &__timer {
    margin: 16px auto 0px auto;
    min-height: 14px;
  }
}
.icon-disabled {
  display: inline-block;
  position: relative;
}
.icon-disabled::after {
  content: '';
  position: absolute;
  width: 41px;
  height: 1px;
  background: #9a9a9a;
  transform: rotate(135deg);
  top: 15%;
  left: -50%;
}
</style>
