<template>
  <div>
    <button
      class="btn"
      :class="buttonClass"
      :disabled="disabled"
      @click="handle"
    >
      <component
        :is="icon"
        v-if="icon"
        class="btn__icon"
        :class="{ 'no-margin-x': text === '' }"
      />
      <span v-if="text !== ''" class="btn__text">{{ text }}</span>
      <component
        :is="iconRight"
        v-if="iconRight"
        class="btn__icon-left"
        :class="{ 'no-margin-x': text === '' }"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import iconClose from '@/components/icons/iconClose.vue';
import iconArrowLeft from '@/components/icons/iconArrowLeft.vue';
import iconEditPen from '@/components/icons/iconEditPen.vue';
import iconConfirm from '@/components/icons/iconConfirm.vue';

export default defineComponent({
  name: 'ButtonComponent',
  components: {
    iconClose,
    iconArrowLeft,
    iconEditPen,
    iconConfirm,
  },
  props: {
    buttonClass: {
      type: String,
      default: 'btn-primary',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handle'],
  setup(props, { emit }) {
    const handle = () => {
      emit('handle');
    };

    return {
      handle,
    };
  },
});
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  transition-duration: 0.3s;
  font-size: 14px;
  line-height: 27px;
  font-weight: 500;

  &__icon {
    margin-right: 8px;
  }
  &__icon-left {
    margin-left: 8px;
  }
  &__text {
    font-weight: 500;
  }
  &__link-text {
    font-weight: 400;
  }
  &__size {
    //padding: 12px 12px;
    //border-radius: 3px;
  }
}
.no-margin-x {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
