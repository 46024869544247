<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    style="margin-bottom: 3px"
  >
    <path
      d="M21.67 21.6702C21.4378 21.8778 21.1567 22 20.8633 22C20.57 22 20.2889 21.9023 20.0567 21.6702L15.8156 17.4314C14.1533 18.7507 12.0633 19.5325 9.77778 19.5325C4.37556 19.5325 0 15.1594 0 9.77235C0 4.38534 4.35111 0 9.74111 0C15.1311 0 19.5067 4.37313 19.5067 9.77235C19.5067 12.0444 18.7244 14.1455 17.4044 15.819L21.67 20.0577C22.11 20.4975 22.11 21.2182 21.67 21.6824M9.76556 17.2604C13.8844 17.2604 17.2456 13.9256 17.2456 9.78456C17.2456 5.64353 13.9089 2.27207 9.76556 2.27207C5.62222 2.27207 2.27333 5.64353 2.27333 9.77235C2.27333 13.9012 5.64667 17.2482 9.77778 17.2482"
      :fill="iconColor"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'IconSearch',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 22,
    },
    height: {
      type: [Number, String],
      default: 22,
    },
    viewBox: {
      type: String,
      default: '0 0 22 22',
    },
  },
};
</script>
