import { useAuthStore } from '@/store/authStore';
import Auth from '@/classes/auth';
import { UserLoginResponse } from '@/types/common';

export let userClassApp = new Auth(getAuthObjectLS());

// инициализировать класс user с методами аутентификации
export function createClassUser() {
  userClassApp = new Auth(getAuthObjectLS());
}

// получить объект authDocs из store
export function getAuthObject() {
  const store = useAuthStore();
  const user = store.getUser;
  return user;
}

// получить объект authDocs из localStorage
export function getAuthObjectLS(): UserLoginResponse | null {
  const authDocs = localStorage.getItem('authDocs');
  let result: UserLoginResponse | null = null;
  if (authDocs) {
    result = JSON.parse(authDocs);
  }
  return result;
}
