<template>
  <iconsWrapper :width="width" :height="height" :viewBox="viewBox" fill="none">
    <path
      d="M8.84006 2.89982L3.36673 8.69315C3.16006 8.91315 2.96006 9.34649 2.92006 9.64649L2.6734 11.8065C2.58673 12.5865 3.14673 13.1198 3.92006 12.9865L6.06673 12.6198C6.36673 12.5665 6.78673 12.3465 6.9934 12.1198L12.4667 6.32649C13.4134 5.32649 13.8401 4.18649 12.3667 2.79315C10.9001 1.41315 9.78673 1.89982 8.84006 2.89982Z"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M7.92676 3.8667C8.21342 5.7067 9.70676 7.11337 11.5601 7.30003"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 15.1665H14"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'IconEdit',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    viewBox: {
      type: String,
      default: '0 0 16 16',
    },
  },
};
</script>
