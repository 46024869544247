import axios from 'axios';
import router from '../router';
import { userClassApp } from '@/utils/getAuth';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.response.use(
  function (response) {
    // здесь может быть дополнительная обработка ответов
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('authDocs');
      userClassApp.setUser(null);
      router.push({ name: 'HomePage' });
    }
    return Promise.reject(error);
  }
);

export default axios;
