import { AxiosError } from 'axios';
import errorsInterceptor from '@/utils/errorsInterceptor';
import { snackbarType } from '@/types/common';

type colorTypes = 'success' | 'red';

export default function (error: AxiosError): string[] {
  if (!error.response?.data) {
    return ['Произошла ошибка.'];
  }
  //eslint-disable-next-line
  // @ts-ignore
  if (error.response?.data['message']) {
    //eslint-disable-next-line
    // @ts-ignore
    return [error.response.data['message']];
    //eslint-disable-next-line
    // @ts-ignore
  } else if (error.response?.data['detail']) {
    //eslint-disable-next-line
    // @ts-ignore
    return [error.response.data['detail']];
  }
  return ['Произошла ошибка.'];
}

export function showError(error): void {
  if (error?.response?.status === 401) return;
  const message = errorsInterceptor(error).join(', ');
  const snackbarParams: snackbarType = {
    message: message,
    color: 'red',
  };
  const evt = new CustomEvent('ShowSnackbar', {
    detail: snackbarParams,
  });
  window.dispatchEvent(evt);
}

export function showMessage(
  message: string,
  color: colorTypes = 'success'
): void {
  const snackbarParams: snackbarType = {
    message: message,
    color,
  };
  const evt = new CustomEvent('ShowSnackbar', {
    detail: snackbarParams,
  });
  window.dispatchEvent(evt);
}
