<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-class"
  >
    <path
      d="M14.7002 4.96479C14.5002 4.76479 14.2402 4.65479 13.9402 4.65479C13.6702 4.65479 13.4202 4.77479 13.2302 4.96479L7.34019 10.8648L4.77019 8.27479C4.39019 7.89478 3.71019 7.87478 3.32019 8.23479C3.12019 8.42479 3.00019 8.67479 3.00019 8.95479C2.99019 9.22479 3.09019 9.49479 3.29019 9.70479L6.63019 13.0548C6.83019 13.2548 7.09019 13.3448 7.36019 13.3448C7.63019 13.3448 7.89019 13.2448 8.09019 13.0448L14.6802 6.42479C14.8802 6.23479 14.9902 5.97479 15.0002 5.69479C15.0002 5.41479 14.9002 5.15479 14.7002 4.95479"
      :fill="iconColor"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'IconCheck',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewBox: {
      type: String,
      default: '0 0 18 18',
    },
  },
};
</script>

<style scoped></style>
