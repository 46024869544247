import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import type { routerContextType } from '@/types/common';
import middlewareHandler from '@/middleware/middlewareHandler';
import { isAuth } from '@/middleware/middlewares';
import { userClassApp } from '@/utils/getAuth';
import HomePage from '@/pages/HomePage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import('@/pages/ProfilePage.vue'),
    meta: {
      middleware: [isAuth],
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const user = userClassApp;
  const middleware: any = to.meta.middleware;

  const context: routerContextType = {
    to,
    from,
    next,
    user,
  };

  return middlewareHandler(context, middleware);
});

export default router;
