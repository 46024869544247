import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-797f5a66"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 1,
  class: "btn__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: _normalizeClass(["btn", _ctx.buttonClass]),
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handle && _ctx.handle(...args)))
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
            key: 0,
            class: _normalizeClass(["btn__icon", { 'no-margin-x': _ctx.text === '' }])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.text !== '')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      (_ctx.iconRight)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconRight), {
            key: 2,
            class: _normalizeClass(["btn__icon-left", { 'no-margin-x': _ctx.text === '' }])
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1)
  ]))
}