<template>
  <iconsWrapper
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-class"
  >
    <rect width="22" height="22" rx="11" fill="#3E9D00" />
    <path
      d="M10.9884 4C10.4409 4 9.98669 4.4426 9.98669 5.00166V9.99834H5.00166C4.45424 9.99834 4 10.4409 4 11C4 11.5591 4.4426 12.0017 5.00166 12.0017H9.99834V16.9983C9.99834 17.5458 10.4409 18 11 18C11.5591 18 12.0017 17.5574 12.0017 16.9983V12.0017H16.9983C17.5458 12.0017 18 11.5591 18 11C18 10.4409 17.5574 9.99834 16.9983 9.99834H12.0017V5.00166C12.0017 4.45424 11.5591 4 11 4"
      fill="white"
      fill-opacity="0.85"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'IconPlus',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },
};
</script>

<style scoped></style>
