<template>
  <iconsWrapper :width="width" :height="height" :viewBox="viewBox">
    <circle cx="7" cy="7.5" r="7" fill="#013554" />
    <path
      d="M6.784 8.868C6.68267 8.868 6.60267 8.836 6.544 8.772C6.49067 8.70267 6.464 8.612 6.464 8.5C6.464 8.33467 6.48267 8.18 6.52 8.036C6.56267 7.88667 6.62933 7.73733 6.72 7.588C6.81067 7.43867 6.93067 7.28133 7.08 7.116C7.192 6.988 7.28 6.876 7.344 6.78C7.41333 6.67867 7.46133 6.58267 7.488 6.492C7.52 6.40133 7.536 6.308 7.536 6.212C7.536 6.036 7.472 5.89733 7.344 5.796C7.216 5.69467 7.03467 5.644 6.8 5.644C6.58667 5.644 6.392 5.67333 6.216 5.732C6.04533 5.78533 5.872 5.86267 5.696 5.964C5.57867 6.03333 5.472 6.06533 5.376 6.06C5.28533 6.04933 5.21067 6.01467 5.152 5.956C5.09333 5.89733 5.056 5.82533 5.04 5.74C5.024 5.65467 5.03733 5.56667 5.08 5.476C5.12267 5.38533 5.2 5.30267 5.312 5.228C5.53067 5.084 5.78133 4.97467 6.064 4.9C6.34667 4.82 6.61333 4.78 6.864 4.78C7.2 4.78 7.49333 4.836 7.744 4.948C8 5.06 8.19733 5.21467 8.336 5.412C8.48 5.60933 8.552 5.84133 8.552 6.108C8.552 6.27333 8.528 6.43067 8.48 6.58C8.432 6.724 8.35467 6.87067 8.248 7.02C8.14133 7.164 7.99467 7.32133 7.808 7.492C7.64267 7.64133 7.50933 7.77733 7.408 7.9C7.312 8.02267 7.24 8.14 7.192 8.252C7.14933 8.364 7.12 8.484 7.104 8.612C7.09867 8.68667 7.06667 8.748 7.008 8.796C6.95467 8.844 6.88 8.868 6.784 8.868ZM6.768 10.54C6.58667 10.54 6.44 10.484 6.328 10.372C6.22133 10.26 6.168 10.116 6.168 9.94C6.168 9.76933 6.22133 9.63067 6.328 9.524C6.44 9.412 6.58667 9.356 6.768 9.356C6.95467 9.356 7.09867 9.412 7.2 9.524C7.30667 9.63067 7.36 9.76933 7.36 9.94C7.36 10.116 7.30667 10.26 7.2 10.372C7.09867 10.484 6.95467 10.54 6.768 10.54Z"
      :fill="iconColor"
    />
  </iconsWrapper>
</template>

<script>
import iconsWrapper from '@/components/icons/iconsWrapper.vue';
export default {
  name: 'iconInfo',
  components: {
    iconsWrapper,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 15,
    },
    viewBox: {
      type: String,
      default: '0 0 14 15',
    },
  },
};
</script>
