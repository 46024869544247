import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getListDocumentTypes } from '@/api/homePageService';
import { getUserBalance } from '@/api/balanceService';
import type { IDocumentType } from '@/types/common';

export const useDataStore = defineStore('dataStore', () => {
  const allDocumentTypes = ref<IDocumentType[]>([]);
  const userBalance = ref<number>(0);

  function getBaseData() {
    loadListTypes();
    loadBalance();
  }

  async function loadListTypes() {
    try {
      const response = await getListDocumentTypes();
      if (response) {
        allDocumentTypes.value = response.data.document_types;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadBalance() {
    try {
      const response = await getUserBalance();
      if (response) {
        userBalance.value = response.data.balance;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getDocumentLabel = (code: number) => {
    const document = allDocumentTypes.value.find((doc) => doc.code === code);
    return document ? document.document_type : 'Неизвестный документ';
  };

  return {
    allDocumentTypes,
    loadListTypes,
    getBaseData,
    getDocumentLabel,
    userBalance,
    loadBalance,
  };
});
