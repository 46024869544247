import axios from 'axios';
import { ApiResponse } from '@/types/common';

//Пополнение баланса
export const topUp = (
  params
): ApiResponse<{
  payment_url: string; //Ссылка для подтверждения платежа через YooKassa.
  transaction_id: number; //Идентификатор транзакции в вашей базе данных.
}> => {
  return axios.post('/documents/top_up/', params);
};

//Проверка успешности платежа
export const topUpStatus = (id): ApiResponse<any> => {
  return axios.get(`/documents/transaction_status/${id}`);
};

//Проверка баланса пользователя
export const getUserBalance = (): ApiResponse<{ balance: number }> => {
  return axios.get('/documents/user_balance/');
};
