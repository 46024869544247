import { createApp } from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import { createPinia } from 'pinia';
import { Quasar } from 'quasar';
import quasarLang from 'quasar/lang/ru';
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import VueAxios from 'vue-axios';
import axios from './plugins/axios';

const Vue = createApp(App);

Vue.use(createPinia());
Vue.use(Quasar, {
  plugins: {},
  lang: quasarLang,
});
Vue.use(router);
Vue.use(VueAxios, axios);
Vue.provide('axios', Vue.config.globalProperties.axios);

Vue.mount('#app');
