<template>
  <QDialog v-model="isOpen" @hide="afterLeave">
    <QCard>
      <div class="modal">
        <div class="modal__header-wrapper">
          <h2 class="modal__title">Авторизация</h2>
          <iconClose class="btn gray-btn" @click="close()" />
        </div>
        <div class="modal__field narrow">
          <label class="modal__label">Email</label>
          <QInput
            outlined
            v-model="form.data.contact_info"
            type="text"
            class="home__key-input"
          ></QInput>
          <!-- <span
            class="modal__field-error"
            v-if="v$.contact_info.$dirty && v$.contact_info.$invalid"
          >
            Неверный формат
          </span> -->
        </div>
        <div class="modal__field narrow">
          <label class="modal__label">Пароль</label>
          <QInput
            outlined
            v-model="form.data.password"
            :type="!isShowPass1 ? 'password' : 'text'"
            class="home__key-input"
          >
            <template v-slot:append>
              <iconEye
                @click="isShowPass1 = !isShowPass1"
                :class="{ 'icon-disabled': !isShowPass1 }"
              ></iconEye>
            </template>
          </QInput>
          <!-- <span
            class="modal__field-error"
            v-if="v$.password.$dirty && v$.password.$invalid"
            >Введите пароль</span
          > -->
        </div>
        <div class="modal__field modal__stay-loggined">
          <QCheckbox
            v-model="form.data.stayLoggined"
            label="Оставаться в системе"
          />
          <span class="link" @click="resetPass()">Забыли пароль?</span>
        </div>
        <div class="modal__field">
          <div class="column">
            <button
              class="btn btn-primary"
              :disabled="!isFormValid || state.preloader"
              @click="submit"
            >
              Войти
            </button>
            <span class="modal__no-accaunt"
              >Нет аккаунта?
              <span class="link" @click="openRegistration()"
                >Зарегистрироватья</span
              ></span
            >
          </div>
        </div>
      </div>
    </QCard>
  </QDialog>
</template>

<script lang="ts">
export interface modalType {
  isOpen: boolean;
}

interface IState {
  preloader: boolean;
}

interface IInitData {
  contact_info: string;
  password: string;
  stayLoggined: boolean;
}

interface IForm {
  data: IInitData;
}

const initData = () => ({
  contact_info: '',
  password: '',
  stayLoggined: false,
});

import { defineComponent, ref, reactive, computed, inject } from 'vue';
import iconClose from '@/components/icons/iconClose.vue';
import iconEye from '@/components/icons/iconEye.vue';
import { QDialog, QCard, QInput, QCheckbox } from 'quasar';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { useAuthStore } from '@/store/authStore';
import { useDataStore } from '@/store/dataStore';
import { getUser } from '@/api/authService';
import { showMessage, showError } from '@/utils/errorsInterceptor';
import type { TokenType } from '@/types/common';

export default defineComponent({
  name: 'LoginModal',
  components: {
    iconClose,
    iconEye,
    QDialog,
    QCard,
    QInput,
    QCheckbox,
  },
  emits: ['close', 'reset', 'openRegistration', 'resetPass', 'openAuthModal'],
  props: {},

  setup(props, { emit }) {
    const isShowPass1 = ref(false);
    const axios: any = inject('axios');
    const store = useAuthStore();
    const dataStore = useDataStore();
    const isOpen = ref(false);
    const state: IState = reactive({
      preloader: false,
    });

    const form: IForm = reactive({
      data: initData(),
    });

    const isEmailOrPhone = helpers.withParams(
      { type: 'isEmailOrPhone' },
      (value: string) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^\+?\d{10,15}$/;
        return emailPattern.test(value) || phonePattern.test(value);
      }
    );

    const rules = computed(() => ({
      contact_info: {
        required,
        isEmailOrPhone,
      },
      password: {
        required,
      },
    }));
    const v$ = useVuelidate(rules, form.data);

    async function submit(): Promise<void> {
      try {
        const params = JSON.parse(JSON.stringify(form.data));
        const response = await getUser(params);
        if (response && response.data) {
          state.preloader = false;
          const token: TokenType = response.data.token;
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          store.setUser(response.data, form.data.stayLoggined);
          dataStore.getBaseData();
          close();
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    }

    const openRegistration = (): void => {
      close();
      emit('openRegistration');
    };

    const resetPass = (): void => {
      close();
      emit('resetPass');
    };

    const isFormValid = computed((): boolean => {
      return Boolean(form.data.contact_info && form.data.password);
    });

    const close = () => {
      reset();
      isOpen.value = false;
    };

    const afterLeave = () => {
      reset();
    };

    const reset = (): void => {
      form.data = initData();
      emit('reset');
    };

    return {
      isOpen,
      submit,
      close,
      afterLeave,
      reset,
      form,
      v$,
      state,
      openRegistration,
      resetPass,
      isFormValid,
      isShowPass1,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  padding: 48px;
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 1px;
  }
  &__field {
    position: relative;
    margin-top: 24px;
    min-width: 384px;
  }
  &__field-error {
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: var(--typography-error);
  }
  &__stay-loggined {
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    align-items: center;
  }
  &__no-accaunt {
    margin: 16px auto 0px auto;
  }
}
.icon-disabled {
  display: inline-block;
  position: relative;
}
.icon-disabled::after {
  content: '';
  position: absolute;
  width: 41px;
  height: 1px;
  background: #9a9a9a;
  transform: rotate(135deg);
  top: 15%;
  left: -50%;
}
</style>
