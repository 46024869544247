<template>
  <QDialog v-model="isOpen" @hide="afterLeave">
    <QCard>
      <div class="modal">
        <div class="modal__header-link-wrapper">
          <p class="modal__back link" @click="openAuthModal()">
            Вернуться к авторизации
          </p>
          <iconClose class="btn gray-btn" @click="close()" />
        </div>
        <template v-if="step === 0">
          <div class="modal__header-wrapper">
            <h2 class="modal__title">Сброс пароля</h2>
          </div>
          <div class="modal__field narrow">
            <label class="modal__label">Email</label>
            <QInput
              ref="emailRef"
              outlined
              v-model="form.firstStep.email"
              type="email"
              class="home__key-input"
            ></QInput>
          </div>

          <div class="modal__field">
            <div class="column">
              <button
                class="btn btn-primary"
                :disabled="!form.firstStep.email || state.preloader"
                @click="submitFirst"
              >
                Продолжить
              </button>
            </div>
          </div>
        </template>
        <template v-if="step === 1">
          <h2 class="modal__title">Введите код из email</h2>
          <div class="modal__field">
            <div class="column">
              <div
                class="modal__code-wrapper h-middle"
                @keydown="handleKeyDown"
              >
                <div
                  class="modal__code-input"
                  v-for="(field, index) in form.emailCode"
                  :key="index"
                >
                  <QInput
                    ref="emailCodeRef"
                    outlined
                    v-model="form.emailCode[index]"
                    @update:model-value="(val) => emailCodeChanged(val, index)"
                    @paste="handlePaste($event, index)"
                    @focus="setCurrentInputIndex(index)"
                    maxlength="1"
                  ></QInput>
                </div>
              </div>
            </div>
            <span
              class="modal__field-error"
              v-if="v$.email.$dirty && v$.email.$invalid"
            >
              Неверный формат
            </span>
          </div>
          <div class="modal__field narrow">
            <label class="modal__label">Пароль</label>
            <QInput
              outlined
              v-model="form.secondStep.new_password"
              :type="!isShowPass1 ? 'password' : 'text'"
              class="home__key-input"
              @blur="v$2.new_password.$touch"
              @input="v$2.new_password.$touch"
            >
              <template v-slot:append>
                <iconEye
                  @click="isShowPass1 = !isShowPass1"
                  :class="{ 'icon-disabled': !isShowPass1 }"
                ></iconEye>
              </template>
            </QInput>
            <span
              class="modal__field-error"
              v-if="v$2.new_password.$dirty && !form.secondStep.new_password"
              >Введите пароль</span
            >
          </div>
          <div class="modal__field narrow">
            <label class="modal__label">Подтвердите пароль</label>
            <QInput
              outlined
              v-model="form.secondStep.repeatPass"
              :type="!isShowPass2 ? 'password' : 'text'"
              class="home__key-input"
              @blur="v$2.repeatPass.$touch"
              @input="v$2.repeatPass.$touch"
            >
              <template v-slot:append>
                <iconEye
                  @click="isShowPass2 = !isShowPass2"
                  :class="{ 'icon-disabled': !isShowPass2 }"
                ></iconEye>
              </template>
            </QInput>
            <span
              class="modal__field-error"
              v-if="
                v$2.repeatPass.$dirty &&
                form.secondStep.repeatPass !== form.secondStep.new_password
              "
              >Пароли должны совпадать</span
            >
          </div>
          <div class="modal__field">
            <div class="column">
              <button
                class="btn btn-primary"
                :disabled="
                  !isEmailCodeValid ||
                  !form.secondStep.new_password ||
                  form.secondStep.repeatPass !== form.secondStep.new_password ||
                  state.preloader
                "
                @click="submitSecond"
              >
                Сбросить пароль
              </button>
            </div>
          </div>
        </template>
      </div>
    </QCard>
  </QDialog>
</template>

<script lang="ts">
export interface modalType {
  isOpen: boolean;
}

interface IState {
  preloader: boolean;
}

interface IForm {
  firstStep: firstStepData;
  secondStep: secondStepData;
  emailCode: Record<number, string>;
}

interface firstStepData {
  email: string;
}

interface secondStepData {
  new_password: string;
  repeatPass: string;
}

const initFirstStepData = () => ({
  email: '',
});

const initSecondStepData = () => ({
  new_password: '',
  repeatPass: '',
});

const initEmailCode = () => ({
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
});

import { defineComponent, ref, reactive, computed } from 'vue';
import iconClose from '@/components/icons/iconClose.vue';
import { QDialog, QCard, QInput, QCheckbox } from 'quasar';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import iconArrowLeft from '@/components/icons/iconArrowLeft.vue';
import { confirmPasswordReset, resetPass } from '@/api/authService';
import { showError } from '@/utils/errorsInterceptor';
import iconEye from '@/components/icons/iconEye.vue';

export default defineComponent({
  name: 'ResetPasswordModal',
  components: {
    iconClose,
    iconArrowLeft,
    iconEye,
    QDialog,
    QCard,
    QInput,
    QCheckbox,
  },
  emits: ['close', 'openAuthModal'],
  props: {},

  setup(props, { emit }) {
    const isShowPass1 = ref(false);
    const isShowPass2 = ref(false);
    const step = ref(0);
    const isOpen = ref(false);
    const state: IState = reactive({
      preloader: false,
    });
    const currentInputIndex = ref(0); // Индекс текущего инпута с фокусом

    const form = reactive<IForm>({
      firstStep: initFirstStepData(),
      secondStep: initSecondStepData(),
      emailCode: initEmailCode(),
    });

    const repeatPassValidation = helpers.withParams(
      { type: 'repeatPassValidation' },
      (value: string) => {
        return value === form.secondStep.new_password;
      }
    );

    const rules = computed(() => ({
      email: {
        required,
      },
    }));
    const v$ = useVuelidate(rules, form.firstStep);

    const rules2 = computed(() => ({
      new_password: {
        required,
      },
      repeatPass: {
        required,
        repeatPassValidation,
      },
    }));
    const v$2 = useVuelidate(rules2, form.secondStep);

    const isEmailCodeValid = computed((): boolean => {
      let res = true;
      for (let key in form.emailCode) {
        if (form.emailCode[key] === '') {
          res = false;
        }
      }
      return res;
    });

    const emailCodeChanged = (value, index) => {
      if (value !== '' && value !== null) {
        if (emailCodeRef.value[+index + 1]) {
          emailCodeRef.value[+index + 1].focus();
        }
      }
    };

    const handlePaste = (event, index) => {
      // Предотвращаем обычное поведение вставки
      event.preventDefault();
      // Получаем вставляемые данные
      const pastedData = event.clipboardData.getData('text/plain').slice(0, 6); // Ограничиваем длину 6 символами
      // Обновляем значения форм
      for (let key in form.emailCode) {
        form.emailCode[key] = pastedData[key] || '';
      }
    };

    const setCurrentInputIndex = (index) => {
      currentInputIndex.value = index; // Устанавливаем текущий индекс инпута
    };
    const handleKeyDown = (event) => {
      if (event.key === 'Backspace') {
        event.preventDefault();
        for (let key in form.emailCode) {
          form.emailCode[key] = '';
        }
      }
    };

    const submitFirst = async (): Promise<void> => {
      const params = {
        contact_info: form.firstStep.email,
      };
      state.preloader = true;
      try {
        const response = await resetPass(params);
        state.preloader = false;
        if (response && response.data) {
          step.value = 1;
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    };

    const back = (): void => {
      step.value = 0;
      if (emailRef.value) {
        emailRef.value.focus();
      }
    };

    const submitSecond = async (): Promise<void> => {
      const params = {
        contact_info: form.firstStep.email,
        new_password: form.secondStep.new_password,
        reset_code: '',
      };
      for (let key in form.emailCode) {
        params.reset_code = params.reset_code + form.emailCode[key];
      }
      state.preloader = true;
      try {
        const response = await confirmPasswordReset(params);
        state.preloader = false;
        if (response && response.data) {
          close();
          openAuthModal();
        }
      } catch (error) {
        showError(error);
        state.preloader = false;
      }
    };

    const close = () => {
      reset();
      isOpen.value = false;
    };

    const afterLeave = () => {
      reset();
      emit('close');
    };

    const reset = () => {
      step.value = 0;
      form.firstStep = initFirstStepData();
      form.secondStep = initSecondStepData();
      form.emailCode = initEmailCode();
    };

    const openAuthModal = (): void => {
      close();
      emit('openAuthModal');
    };

    const emailRef = ref<any | null>(null);
    const emailCodeRef = ref<any | null>(null);

    return {
      isOpen,
      submitFirst,
      close,
      afterLeave,
      form,
      v$,
      v$2,
      state,
      step,
      submitSecond,
      isEmailCodeValid,
      emailCodeChanged,
      handlePaste,
      emailCodeRef,
      emailRef,
      back,
      isShowPass1,
      isShowPass2,
      openAuthModal,
      currentInputIndex,
      setCurrentInputIndex,
      handleKeyDown,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  padding: 48px;
  display: flex;
  flex-direction: column;
  &__back {
    display: flex;
    column-gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 1px;
    text-align: center;
  }
  &__field {
    margin-top: 24px;
    min-width: 384px;
    position: relative;
  }
  &__field-error {
    position: absolute;
    bottom: -19px;
    font-size: 12px;
    color: var(--typography-error);
  }
  &__stay-loggined {
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    align-items: center;
  }
  &__no-accaunt {
    margin: 16px auto 0px auto;
  }
  &__code-input {
    width: 37px;
  }
  &__code-wrapper {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 14px;
    justify-content: center;
  }
  &__timer {
    margin: 16px auto 0px auto;
    min-height: 14px;
  }
}
.icon-disabled {
  display: inline-block;
  position: relative;
}
.icon-disabled::after {
  content: '';
  position: absolute;
  width: 41px;
  height: 1px;
  background: #9a9a9a;
  transform: rotate(135deg);
  top: 15%;
  left: -50%;
}
</style>
