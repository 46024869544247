import axios from 'axios';
import { ApiResponse, UserLoginResponse, userType } from '@/types/common';

export const getUser = (params: object): ApiResponse<UserLoginResponse> => {
  return axios.post('/login/', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const userRegistration = (params: object): ApiResponse<any> => {
  return axios.post('/register/', params);
};

export const verifyRegistration = (params: object): ApiResponse<any> => {
  return axios.post('/verify/', params);
};

export const requestConfirmationCode = (params: object): ApiResponse<any> => {
  return axios.post('/request_confirmation_code/', params);
};

export const resetPass = (params: object): ApiResponse<any> => {
  return axios.post('/reset_password/', params);
};

export const confirmPasswordReset = (params: object): ApiResponse<any> => {
  return axios.post('/confirm_password_reset/', params);
};
