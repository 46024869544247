<template>
  <div class="layout">
    <HeaderComponent></HeaderComponent>
    <div class="layout__main">
      <div class="layout__content custom-scroll">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { UserLoginResponse } from '@/types/common';
import { useAuthStore } from '@/store/authStore';
import HeaderComponent from '@/components/header/HeaderComponent.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: { HeaderComponent },
  setup() {
    const store = useAuthStore();
    const isAuth = computed((): UserLoginResponse | null => store.userStore);

    return { isAuth };
  },
});
</script>

<style lang="scss" scoped>
.layout {
  height: 99%;
  &__main {
    display: flex;
    flex-wrap: nowrap;
    //height: calc(100vh - 108px);
    width: 100%;
  }
  &__content {
    height: 100%;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 122px);
  }
}
</style>
