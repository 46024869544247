import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0b0eacb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = { class: "modal__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QCard = _resolveComponent("QCard")!
  const _component_QDialog = _resolveComponent("QDialog")!

  return (_openBlock(), _createBlock(_component_QDialog, {
    modelValue: _ctx.state.showSnackbar,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.showSnackbar) = $event)),
    onHide: _ctx.afterLeave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_QCard, { style: {"max-width":"80vw"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["modal", _ctx.state.color])
          }, [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.state.message), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, "Закрыть")
            ])
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "onHide"]))
}